import axios from 'axios';
import { omit } from 'lodash';
import { getToken } from 'src/utils/authUtils';
import { DeviceUUID } from 'device-uuid';

import * as config from '../config';

const Axios = axios.create({
  baseURL: config.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

Axios.interceptors.request.use(req => {
  const authToken = getToken('auth');
  const otpToken = getToken('otp');
  const apiKey = config.API_KEY;
  const useApiKey = req.params?.useApiKey;
  const useOtpToken = req.params?.useOtpToken;
  const deviceUUID = new DeviceUUID().get();

  req.headers['xapi-device-id'] = deviceUUID;

  if (useApiKey && apiKey) {
    req.params = omit(req.params, ['useApiKey']);
    req.headers.Authorization = `Bearer ${apiKey}`;
    return req;
  }

  if (useOtpToken && otpToken) {
    req.params = omit(req.params, ['useOtpToken']);
    req.headers.Authorization = `Bearer ${otpToken}`;
    return req;
  }

  if (authToken) {
    req.headers.Authorization = `Bearer ${authToken}`;
  } else {
    req.headers.Authorization = `Bearer ${apiKey}`;
  }
  return req;
});

export default Axios;
