import { ReducerRegistry } from 'src/redux';
import { ReduxStore } from 'src/types';

import {
  REGISTER,
  FETCH_ACTIVE_SESSION,
  VERIFY_OTP,
  LOGIN,
  SET_SESSION_TIMEOUT,
  LOGOUT,
} from './actionTypes';

type AuthReducerState = ReduxStore['module/auth'];

const DEFAULT_STATE: AuthReducerState = {
  isLoggedIn: false,
  hasSessionTimedOut: false,
  profile: null,
};

/**
 * Reduces the Redux actions of the feature features/e2ee.
 */
ReducerRegistry.register<AuthReducerState>(
  'module/auth',
  (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case LOGIN:
        return {
          ...state,
          isLoggedIn: true,
          profile: action.payload,
        };
      case VERIFY_OTP:
        return {
          ...state,
          isLoggedIn: true,
          profile: action.payload,
        };
      case REGISTER:
        return {
          ...state,
          isLoggedIn: true,
          profile: action.payload,
        };
      case FETCH_ACTIVE_SESSION:
        return {
          ...state,
          isLoggedIn: true,
          profile: action.payload,
        };
      case SET_SESSION_TIMEOUT:
        return {
          ...state,
          hasSessionTimedOut: action.payload,
        };

      case LOGOUT:
        return DEFAULT_STATE;
      default:
        return state;
    }
  },
);
