import { AsyncWrapper } from 'src/helpers';

import * as AT from './actionTypes';
import * as api from '../api';

export const getAccounts = () => {
  return {
    types: [AT.GET_ACCOUNTS, AT.GET_ACCOUNTS_SUCCESS, AT.GET_ACCOUNTS_FAILURE],
    promise: async () => {
      const [result] = await AsyncWrapper(
        api.getAccounts({
          limit: 1000,
          include: 'total_outgoing,signatories',
        }),
      );
      return result;
    },
  };
};
