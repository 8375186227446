import { differenceInDays, startOfDay } from 'date-fns';

import { BRASS_LAUNCH_MONTH, BRASS_LAUNCH_YEAR } from 'src/data';

type Limit = {
  min?: Date;
  max: Date;
};

const isDateDisabled =
  (type: 'start' | 'end' = 'start', value: string, limit?: Limit) =>
  (date: Date) => {
    const defaultLimit = {
      min: new Date(BRASS_LAUNCH_YEAR, BRASS_LAUNCH_MONTH - 1),
      max: new Date(),
    };
    limit = {
      ...defaultLimit,
      ...limit,
    };
    if (type === 'start' && value) {
      return (
        differenceInDays(startOfDay(date), startOfDay(new Date(value))) >= 0 ||
        differenceInDays(date, limit.min!) < 0
      );
    }

    if (type === 'end' && value) {
      return (
        differenceInDays(startOfDay(date), startOfDay(new Date(value))) <= 0 ||
        differenceInDays(startOfDay(date), startOfDay(limit.max)) > 0
      );
    }

    return (
      differenceInDays(date, limit.min!) < 0 ||
      differenceInDays(startOfDay(date), startOfDay(limit?.max)) > 0
    );
  };

export default isDateDisabled;
