import {
  differenceInHours,
  fromUnixTime,
  formatDistanceToNowStrict,
  format,
} from 'date-fns';
import { ToWords } from 'to-words';
import { notifySuccess, notifyError } from 'brass-ui-kit';
import fileDownload from 'js-file-download';
import * as Yup from 'yup';
import { File, OS } from 'src/types';
import { isEmpty, isPlainObject, transform } from 'lodash';
import { Transaction } from 'src/types/entities';

export const truncate = (text = '', length: number) =>
  text ? (text.length <= length ? text : `${text.substr(0, length)}...`) : '';

export const capitalize = (text = '') => {
  return text
    ? text
        .split(' ')
        .map(
          item =>
            `${item.charAt(0).toUpperCase()}${item.slice(1).toLowerCase()}`,
        )
        .join(' ')
    : '';
};

export const getDaysFromNow = (unix: number) => {
  const now = new Date();
  const dateToCompare = fromUnixTime(unix);
  return differenceInHours(dateToCompare, now) / 24;
};

export interface CurrentDate {
  month: string;
  year: string;
  day: string;
}

export const createDateString = ({ month, year, day }: CurrentDate) => {
  return `${year}-${month}-${day}`;
};

export const copyToClipboard = (text: string): Promise<any> =>
  new Promise((resolve, reject) => {
    try {
      if (!!navigator.clipboard && !!navigator.clipboard.writeText) {
        return resolve(navigator.clipboard.writeText(text));
      }
      return reject(new Error('Copy to clipboard failed'));
    } catch (error) {
      reject(error);
    }
  });

export const downloadFile = async ({ url, key = '', content_type }: File) => {
  const data = await fetch(url).then(res => res.blob());
  const filename = [...key.split('/')].pop() || '';
  fileDownload(data, filename, content_type);
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
});

export const formatAmountToNaira = (amount: number) => {
  const nairaAmount = amount / 100;
  return currencyFormatter.format(nairaAmount);
};

export const groupTransactionsByMonth = (transactions: Transaction[]) => {
  return Object.entries(
    transactions.reduce((b, a) => {
      const m = `${a.created_at.split('T')[0].substr(0, 7)}-01`;
      if (b.hasOwnProperty(m)) b[m].push(a);
      else b[m] = [a];
      return b;
    }, {}),
  )
    .sort((b, a) => a[0].localeCompare(b[0]))
    .map(e => ({
      month: format(new Date(e[0]), 'MMMM'),
      data: e[1] as Transaction[],
    }));
};

export const getCauserData = (
  trx: any,
): { name: string; bank: string; accountNumber: string } => {
  const info = {
    name: '',
    bank: '',
    accountNumber: '',
  };
  const { causer_type, causer } = trx;

  switch (causer_type) {
    case 'disbursement':
      info.name =
        causer?.data?.counterparty?.data?.counterparty?.data?.name || '';
      info.accountNumber =
        causer?.data?.counterparty?.data?.counterparty?.data?.number || '';
      info.bank =
        causer?.data?.counterparty?.data?.counterparty?.data?.bank?.data
          ?.name || '';
      return info;
    case 'counterparty':
      info.name = causer?.data?.counterparty?.data?.name || '';
      info.accountNumber = causer?.data?.counterparty?.data?.number || '';
      info.bank = causer?.data?.counterparty?.data?.bank?.data?.name || '';
      return info;
    case 'user':
      info.name = `${causer?.data?.firstname || ''} ${
        causer?.data?.lastname || ''
      }`;
      return info;
    case 'cash_inflow':
      info.name = causer?.data?.payer?.data?.name;
      info.bank = causer?.data?.payer?.data?.bank
        ? causer.data.payer.data.bank.data.name
        : '';
      info.accountNumber = causer?.data?.payer?.data?.number || '';
      return info;
    case 'account':
    case 'contact':
      info.name = causer?.data?.name || '';
      info.bank = causer?.data?.bank?.data?.name || '';
      info.accountNumber = causer?.data?.number || '';
      return info;
    case 'customer':
      info.name = causer?.data?.name || '';
      return info;

    case 'terminal_settlement':
      info.name =
        `Terminal ${causer?.data?.terminal?.data?.serial_number}` || '';
      return info;
    case 'capital_withdrawal_repayment':
    case 'capital_withdrawal':
      info.name = causer?.data?.name || '';
      return info;
    case null:
    case undefined:
      if (!(trx as any).counterparties?.data[0]) {
        return info;
      }
      const data = (trx as any)?.counterparties?.data[0]?.counterparty?.data;
      info.name = data?.name || '';
      info.bank = data?.bank?.data?.name || '';
      info.accountNumber = data?.number || '';
      return info;
    default:
      return info;
  }
};

export const koboToNaira = (koboValue: number) =>
  currencyFormatter.format(koboValue / 100);

export const formatNumWithCommas = (num: string) => {
  let str = parseInt(num).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });
  str = str.replace(/\./, '.');
  str = str.replace(/,/g, ',');
  return str;
};

export const convertNumberToWords = (number: number) => {
  const toWords = new ToWords({
    localeCode: 'en-US',
    converterOptions: {
      currency: false,
      ignoreDecimal: true,
    },
  });
  return toWords.convert(number);
};

const getShort = (value: number, exp: number, char: string) => {
  return parseFloat((value / Math.pow(10, exp)).toFixed(1)).toString() + char;
};

export const shortenAmount = (value: number) => {
  const absValue = Math.abs(value);
  let a = '';

  if (absValue >= Math.pow(10, 12)) {
    a = getShort(value, 12, 'T');
  } else if (absValue >= Math.pow(10, 9)) {
    a = getShort(value, 9, 'B');
  } else if (absValue >= Math.pow(10, 6)) {
    a = getShort(value, 6, 'M');
  } else if (absValue >= Math.pow(10, 3)) {
    a = getShort(value, 3, 'K');
  }

  return a;
};

export const copyText = ({
  text,
  successMessage = 'Copied to clipboard',
  errorMessage = 'Oops!! Unable to copy to clipboard',
}: {
  text: string;
  successMessage?: string;
  errorMessage?: string;
}) => {
  try {
    if (!!navigator.clipboard && !!navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text);
    }

    notifySuccess(successMessage);
  } catch (error) {
    notifyError(errorMessage);
  }
};

export const copyBankInfo = ({
  name,
  number,
  bank,
}: {
  name: string;
  number: string;
  bank: string;
}) => {
  copyText({
    text: `Account name: ${name},${' '}Bank name: ${bank},${' '}Account number: ${number}`,
    successMessage: 'Account information copied to clipboard',
  });
};

export function clean<T = any>(object: T) {
  return transform<any, T>(object, (result: any, value, key) => {
    // Recurse into arrays and objects.
    if (Array.isArray(value) || isPlainObject(value)) {
      value = clean(value);
    }

    // Exclude empty objects.
    if (isPlainObject(value) && isEmpty(value)) {
      return;
    }

    // Exclude empty arrays.
    if (Array.isArray(value) && !value.length) {
      return;
    }

    // Exclude empty strings.
    if (value === '') {
      return;
    }

    // Exclude NaN values.
    if (Number.isNaN(value)) {
      return;
    }

    // Exclude null values.
    if (value === null) {
      return;
    }

    // Exclude undefined values.
    if (value === undefined) {
      return;
    }

    // Append when recursing arrays.
    if (Array.isArray(result)) {
      return result.push(value);
    }

    result[key] = value;
  });
}

export const convertImageToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        resolve({ imageURL: e?.target?.result });
      };
      reader.onerror = function () {
        reject(new Error('Image conversion failed'));
      };
      reader.readAsDataURL(file);
    } else {
      reject(new Error('Image is empty'));
    }
  });
};

const isValidCountryCode = (countryCode: any): boolean =>
  typeof countryCode === 'string' && countryCode.length === 2;

export const validatePhoneNumber = (
  errorMessage: string,
  options = {
    countryCode: 'NG',
  },
) => {
  let PhoneNumberUtil: any;
  import(
    /* webpackChunkName: "google-libphonenumber" */ 'google-libphonenumber'
  ).then(({ PhoneNumberUtil: util }) => (PhoneNumberUtil = util));

  return Yup.string().test(
    'is-phone-number-valid',
    errorMessage,
    (value?: string | null) => {
      if (!isValidCountryCode(options.countryCode) || !value) return false;
      const phoneUtil = PhoneNumberUtil.getInstance();
      try {
        if (PhoneNumberUtil) {
          const phoneNumber = phoneUtil.parseAndKeepRawInput(
            value!,
            options.countryCode,
          );

          return phoneUtil.isPossibleNumber(phoneNumber);

          // return phoneUtil.isValidNumberForRegion(
          //   phoneNumber,
          //   options.countryCode,
          // );
        }
        return false;
      } catch (error) {
        return false;
      }
    },
  );
};

export const getFilenameFromUrl = (url: string) => {
  if (!url) return '';
  return url?.split('/')?.splice(-2, 2)?.join('/') || '';
};

export const getAccountAlias = (
  accountName = '',
  businessName = '',
  isDefault?: boolean,
) =>
  isDefault ? 'Brass main account' : accountName.slice(businessName.length + 3);

export const generateCookieExpiry = (expiryInMins: number) =>
  1 / ((60 * 24) / expiryInMins);

export const getDueDateInWords = (due_at: string) =>
  formatDistanceToNowStrict(new Date(due_at), {
    addSuffix: true,
    roundingMethod: 'floor',
  }).toUpperCase();

export const getOS = () => {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = OS.DEFAULT;
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OS.MACOS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OS.IOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OS.WINDOWS;
  } else if (/Android/.test(userAgent)) {
    os = OS.ANDROID;
  } else if (/Linux/.test(platform)) {
    os = OS.LINUX;
  }
  return os;
};

export const deeplinker = (location: any) => {
  const os = getOS();
  if ([OS.ANDROID, OS.IOS].includes(os)) {
    if (location?.hash) {
      window.location.href = `brassmoney://${window.location.hostname}${location?.pathname}/${location?.hash}`;
    } else {
      const searchParams = new URLSearchParams(location?.search);
      window.location.href = `brassmoney://${window.location.hostname}${
        location?.pathname
      }?${searchParams.toString()}`;
    }
  } else {
    alert('Please visit this link on a mobile device');
  }
};
