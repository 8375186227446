/* Variants are ordered from light colors to dark i.e
'GREEN-1' is the lightest shade, and 'GREEN-6' is the darkest
*/
import InviteIcon from '../assets/images/invite-icon.png';
import SharedAccountIcon from '../assets/images/shared-account-icon.png';

export const BRASS_LAUNCH_YEAR = 2020;
export const BRASS_LAUNCH_MONTH = 1;
export const CURRENT_YEAR = new Date().getFullYear();
export const DATE_FORMAT = 'yyyy-MM-dd';

export const BRASS_COLORS = {
  'GREEN-1': '#843738',
  'GREEN-2': '#843765',
  'GREEN-3': '#843765',
  'GREEN-4': '#843765',
  'GREEN-5': '#843765',
  'GREEN-6': '#843765',
};

export const MOBILE_APP_DOWNLOAD_ANDRIOD: string =
  process.env.REACT_APP_MOBILE_APP_DOWNLOAD_ANDRIOD!;
export const MOBILE_APP_DOWNLOAD_IOS: string =
  process.env.REACT_APP_MOBILE_APP_DOWNLOAD_IOS!;

export const PAYMENT_STATUS_COLORS = {
  success: BRASS_COLORS['GREEN-1'],
};

export const DEEPLINK_CONFIG = [
  {
    icon: InviteIcon,
    title: 'Invitation to join Brassmoney',
    description:
      'Your friend has invited you to join Brassmoney. Click the button below to accept the invitation.',
    action: 'Accept Invitation',
    route: '/invitation',
  },
  {
    icon: SharedAccountIcon,
    title: 'Invitation to join Brassmoney Shared Account',
    description:
      'Your partner has invited you to join a Shared Account on Brassmoney. Click the button below to accept the invitation.',
    action: 'Accept Invitation',
    route: '/shared-account',
  },
];
