import { AxiosError } from 'axios';
import { notifyError } from 'brass-ui-kit';
// import { captureException as SentryCaptureException } from '@sentry/react';
import { store } from 'src/modules/app';
import { logout, setSessionTimeout } from 'src/modules/auth';
import { getToken } from './authUtils';
import { ReduxStore } from 'src/types';

const reduceMessages = (obj: any = {}) =>
  Object.keys(obj).reduce(
    (acc: any[], key: any) => acc.concat(`${obj[key]}`),
    [],
  );

const getErrorMessage = (
  action = {},
  hasSessionTimedOut: boolean,
  isLoggedIn: boolean,
): string[] => {
  const { error = {} }: any = action;
  const isAuthTokenInstorage = Boolean(getToken('auth'));

  if (error.status === 401 && isLoggedIn && !isAuthTokenInstorage) {
    store.dispatch(logout(true));
    return [];
  }

  if (hasSessionTimedOut) return [];

  if (error.status === 403) {
    !hasSessionTimedOut &&
      isAuthTokenInstorage &&
      store.dispatch(setSessionTimeout(true));
    return [error.description];
  }

  if (error.status === 422) {
    if (Object.keys(error.source).length > 0) {
      const firstError: any = Object.values(error.source)[0];
      return [firstError];
    }
  }
  if (error.status >= 400 && error.status < 500) {
    if (Array.isArray(error.errors)) {
      return error.errors.map((err: any) => reduceMessages(err)).flat();
    }
    if (error) return [error.description];
  }
  return [error?.description || 'Something went wrong'];
};

export const handleError = (err: AxiosError): void => {
  const {
    'module/auth': { isLoggedIn, hasSessionTimedOut },
  }: ReduxStore = store.getState();
  if (err) {
    const errorMessage = getErrorMessage(
      { error: err?.response?.data?.error },
      hasSessionTimedOut,
      isLoggedIn,
    );
    const combinedErrorMessage =
      errorMessage.length > 1
        ? errorMessage.join(', ')
        : errorMessage.toString();
    if (combinedErrorMessage) {
      notifyError(
        combinedErrorMessage,
        !isLoggedIn ? { position: 'top-center' } : {},
      );
    }
    // Report error to sentry
    // SentryCaptureException(err);
    // throw err;
  }
};
