import React from 'react';

//Icons
import {
  ActivityInactiveIcon,
  ActivityActiveIcon,
  PaymentsInactiveIcon,
  PaymentsActiveIcon,
  PendingTasksInactiveIcon,
  PendingTasksActiveIcon,
  TransactionInactiveIcon,
  TransactionActiveIcon,
  InstantPayInactiveIcon,
  InstantPayActiveIcon,
  InvitesInactiveIcon,
  InvitesActiveIcon,
  CardsInactiveIcon,
  CardsActiveIcon,
  BudgetInactiveIcon,
  BudgetActiveIcon,
} from 'src/assets/svgs';

//Modules
import PendingTasks from 'src/modules/pending-tasks';
import Budget from 'src/modules/budget';
import Cards from 'src/modules/cards';

import { Modules } from 'src/types';
import { RouteConfigComponents } from '../types';
import Activity from 'src/modules/activity';
import Transactions from 'src/modules/transactions/pages/Transactions';

export const ROUTE_CONFIG_COMPONENTS: RouteConfigComponents = {
  [Modules.PendingTasks]: {
    activeIcon: PendingTasksActiveIcon,
    inactiveIcon: PendingTasksInactiveIcon,
    component: PendingTasks,
  },
  [Modules.Activity]: {
    activeIcon: ActivityActiveIcon,
    inactiveIcon: ActivityInactiveIcon,
    component: Activity,
  },
  [Modules.Budget]: {
    activeIcon: BudgetActiveIcon,
    inactiveIcon: BudgetInactiveIcon,
    component: Budget,
  },
  [Modules.Cards]: {
    activeIcon: CardsActiveIcon,
    inactiveIcon: CardsInactiveIcon,
    component: Cards,
  },
  [Modules.Payments]: {
    activeIcon: PaymentsActiveIcon,
    inactiveIcon: PaymentsInactiveIcon,
    component: () => <h1>Payments</h1>,
  },
  [Modules.Transactions]: {
    activeIcon: TransactionActiveIcon,
    inactiveIcon: TransactionInactiveIcon,
    component: Transactions,
  },
  [Modules.InstantPay]: {
    activeIcon: InstantPayActiveIcon,
    inactiveIcon: InstantPayInactiveIcon,
    component: () => <h1>Instant Pay</h1>,
  },
  [Modules.Invites]: {
    activeIcon: InvitesActiveIcon,
    inactiveIcon: InvitesInactiveIcon,
    component: () => <h1>Invites</h1>,
  },
};
