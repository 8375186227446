import React from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { isEmpty, omit } from 'lodash';
import { clean } from '../genericUtils';

export function useFilter<T = any>(
  defaultFilters: Partial<T>,
  requestHandler: (filters: Partial<T>) => void,
  deps: React.DependencyList = [],
) {
  const location = useLocation();
  const [filters, setFilters] = React.useState<Partial<T>>(defaultFilters);
  const searchParams: any = omit(qs.parse(location.search.substring(1)), [
    'id',
  ]);

  const updateFilters = (filtersUpdate: Partial<T>) =>
    setFilters(filters => ({ ...filters, ...filtersUpdate }));

  const filterHandler = React.useCallback(() => {
    if (searchParams.min_amount)
      searchParams.min_amount = searchParams.min_amount * 100;
    if (searchParams.max_amount)
      searchParams.max_amount = searchParams.max_amount * 100;
    requestHandler(clean({ ...filters, ...searchParams }));
  }, [JSON.stringify(filters), searchParams, requestHandler]);

  React.useEffect(() => {
    if (!isEmpty(searchParams) || !isEmpty(filters)) {
      filterHandler();
    }
  }, [filters, qs.stringify(searchParams)]);

  React.useEffect(() => {
    if (!!deps?.length) {
      setFilters(defaultFilters);
    }
  }, [...deps]);

  return { updateFilters, filters, filterData: filterHandler };
}
