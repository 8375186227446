import { omit } from 'lodash';
import { ProfileData, ProfileDataWithToken } from 'src/types';
import { clearToken, setToken, TokenKey } from 'src/utils/authUtils';

export const sanitizeProfile = <T>(profile: any): T =>
  omit(profile, ['token', 'token_expiry']);

export const handleToken = async (
  profile: ProfileDataWithToken,
  type: TokenKey,
) => {
  const { token } = profile;

  await clearToken('*');
  await setToken(type, token);
  const sanitizedProfile = sanitizeProfile<ProfileData>(profile);
  return sanitizedProfile;
};
