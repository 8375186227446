import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { Integrations as SentryIntegrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

import App from './modules/app';
import 'url-search-params-polyfill';
import { PageLoader } from './components/PageLoader';
import { version as appVersion } from '../package.json';
import { name as appName } from './app.json';
import { SENTRY_KEY, SENTRY_TRACES_SAMPLE_RATE, SENTRY_ENV } from './config';

Sentry.init({
  dsn: SENTRY_KEY,
  environment: SENTRY_ENV,
  release: `${appName}@${appVersion}`,
  integrations: [new SentryIntegrations.BrowserTracing()],
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
});

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<PageLoader />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
