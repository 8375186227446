import {
  postChangePassword,
  postResendOtp,
  postResetPassword,
  postVerifyEmailHash,
  postVerifyResetPasswordHash,
} from './api';
import {
  ATChangePassword,
  ATResetPassword,
  ATVerifyEmailHash,
  ATVerifyPasswordResetHash,
  RDTAuthOtp,
} from './types';
import { notifySuccess } from 'brass-ui-kit';

import { AsyncWrapper } from '../../helpers';
import { ReduxStore } from '../../types';
import { store } from '../app';

export const resetPassword: ATResetPassword = async (email, successCb) => {
  const [response] = await AsyncWrapper(postResetPassword(email));
  response && successCb();
};

export const changePassword: ATChangePassword = async (
  hash,
  password,
  password_confirmation,
  successCb,
) => {
  const [response] = await AsyncWrapper(
    postChangePassword(hash, password, password_confirmation),
  );
  response && successCb();
};

export const verifyPasswordResetHash: ATVerifyPasswordResetHash = async (
  hash,
  successCb,
) => {
  const [result] = await AsyncWrapper(
    postVerifyResetPasswordHash(hash),
    () => null,
  );
  if (result) {
    successCb(result?.data?.is_used);
  } else {
    successCb(true);
  }
};

export const verifyEmailHash: ATVerifyEmailHash = async (
  hash,
  successCb,
  errorCb,
) => {
  const [result] = await AsyncWrapper(postVerifyEmailHash(hash), e =>
    errorCb(e),
  );
  if (result) {
    successCb(true);
  }
};

export const resendOtp = async (data: RDTAuthOtp) => {
  const {
    'module/auth': { isLoggedIn },
  }: ReduxStore = store.getState();
  const [result] = await AsyncWrapper(postResendOtp(data));
  result &&
    notifySuccess(
      'OTP Code sent successfully!',
      !isLoggedIn ? { position: 'top-center' } : {},
    );
};
