export const REGISTER = 'auth/REGISTER';

export const LOGIN = 'auth/LOGIN';

export const VERIFY_OTP = 'auth/VERIFY_OTP';

export const FETCH_ACTIVE_SESSION = 'auth/FETCH_ACTIVE_SESSION';

export const LOGOUT = 'auth/LOGOUT';

export const SET_SESSION_TIMEOUT = 'auth/SET_SESSION_TIMEOUT';
