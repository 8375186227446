import React from 'react';

export * from './redux';
export * from './types';
export * from './functions';
export * from './utils';
export * as api from './api';

export default React.lazy(
  () => import(/* webpackChunkName: "Authentication" */ './router'),
);
