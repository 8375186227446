export enum Modules {
  PendingTasks = 'pending-tasks',
  Activity = 'activity',
  Budget = 'budget',
  Cards = 'cards',
  Payments = 'payments',
  Transactions = 'transactions',
  InstantPay = 'instant-pay',
  Invites = 'invites',
}

interface ModuleAccessData {
  enabled: boolean;
}

export type ModuleAccess = {
  [key in Modules]: ModuleAccessData;
};
