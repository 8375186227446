import * as Yup from 'yup';

export const filterSchema = Yup.object({
  min_amount: Yup.number(),
  max_amount: Yup.number().when(
    'min_amount',
    (min_amount: number, schema: any) => {
      return min_amount
        ? schema.moreThan(
            min_amount,
            'Maximum amount can’t be less than minimum amount',
          )
        : Yup.number();
    },
  ),
});
