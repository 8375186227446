import { IActionGenObject, IObject } from '../../types';
import { AnyAction, Middleware } from 'redux';

export default function apiRequestMiddleware(helpers: IObject): Middleware {
  return ({ dispatch, getState }) =>
    next =>
    (action: IActionGenObject & AnyAction) => {
      const { promise, types, ...rest } = action;
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      if (REQUEST) next({ ...rest, type: REQUEST });
      const actionPromise = promise(helpers, getState, dispatch);

      actionPromise.then((payload: any) => {
        if (SUCCESS && payload) next({ ...rest, payload, type: SUCCESS });
        //The failure part was added because the error thrown by the error handler crashes
        //the app but this works when the payload is undefined which indicates error
        if (FAILURE && !payload) next({ ...rest, type: FAILURE });
      });
      // .catch((error: any) => {
      //   if (FAILURE)
      //     next({ ...rest, error: error?.response?.data, type: FAILURE });
      // });

      return actionPromise;
    };
}
