import { LOGOUT } from 'src/modules/auth';
import { ROUTE_CONFIG } from 'src/modules/routing/data';
import { ReducerRegistry } from 'src/redux';
import { ReduxStore } from 'src/types';
import * as AT from './actionTypes';

type CommonReducerState = ReduxStore['module/common'];

const DEFAULT_STATE: CommonReducerState = {
  banks: {
    data: [],
  },
  statesList: {
    data: [],
  },
  countriesList: {
    data: [],
  },
  roles: { data: [] },
  industries: { data: [] },
  globalPromptModal: {
    openModal: null,
    data: null,
  },
  routeConfig: ROUTE_CONFIG,
  lienEnabled: false,
};

/**
 * Reduces the Redux actions of the feature features/e2ee.
 */
ReducerRegistry.register<CommonReducerState>(
  'module/common',
  (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case AT.FETCH_BANKS:
        return {
          ...state,
          banks: {
            data: action.payload,
          },
        };
      case AT.FETCH_STATES_LIST:
        return {
          ...state,
          statesList: {
            data: action.payload,
          },
        };
      case AT.FETCH_COUNTRIES_LIST:
        return {
          ...state,
          countriesList: {
            data: action.payload,
          },
        };
      case AT.FETCH_ROLES_AND_PRIVILEGES:
        return {
          ...state,
          roles: {
            data: action.payload,
          },
        };
      case AT.FETCH_INDUSTRIES:
        return {
          ...state,
          industries: {
            data: action.payload,
          },
        };
      case AT.HANDLE_GLOBAL_PROMPT_MODAL:
        return {
          ...state,
          globalPromptModal: {
            ...state.globalPromptModal,
            ...action.payload,
          },
        };
      case AT.CONFIGURE_ROUTES:
        return {
          ...state,
          routeConfig: action.payload,
        };
      case AT.SET_LIEN:
        return {
          ...state,
          lienEnabled: action.payload,
        };
      case LOGOUT:
        return DEFAULT_STATE;
      default:
        return state;
    }
  },
);
