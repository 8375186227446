/**
 * A registry for Redux reducers, allowing features to register themselves
 * without needing to create additional inter-feature dependencies.
 */
import { combineReducers } from 'redux';
import { IAction } from 'src/types';

/*
https://github.com/redux-utilities/flux-standard-action#example
 */

class ReducerRegistry {
  /**
   * Creates a ReducerRegistry instance.
   */
  _elements: any;
  constructor() {
    this._elements = {};
  }

  combineReducers(additional = {}) {
    return combineReducers({
      ...this._elements,
      ...additional,
    });
  }

  register<T = any>(name: string, reducer: (state: T, action: IAction) => T) {
    this._elements[name] = reducer;
  }
}

/**
 * The public singleton instance of the ReducerRegistry class.
 */
export default new ReducerRegistry();
