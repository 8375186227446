import { createStore, compose, Reducer, Action } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import { createReduxEnhancer as SentryCreateReduxEnhancer } from '@sentry/react';
import { persistStore, persistReducer } from 'redux-persist';
import { PERSIST_CONFIG } from '../config';

import MiddlewareRegistry from './middlewareRegistry';
import ReducerRegistry from './reducerRegistry';

const initStore = () => {
  // Create combined reducer from all reducers in ReducerRegistry.
  const rootReducer = ReducerRegistry.combineReducers() as Reducer<
    unknown,
    Action<any>
  >;

  let composedMiddlewares;

  const persistedReducer = persistReducer(PERSIST_CONFIG, rootReducer);

  if (process.env.NODE_ENV === 'development') {
    const devMiddlewares = [logger];
    composedMiddlewares = composeWithDevTools(
      MiddlewareRegistry.applyMiddleware(...devMiddlewares),
    );
  } else {
    composedMiddlewares = MiddlewareRegistry.applyMiddleware();
  }

  const sentryReduxEnhancer = SentryCreateReduxEnhancer({});

  const store: any = createStore(
    persistedReducer,
    compose(composedMiddlewares, sentryReduxEnhancer),
  );

  const persistor = persistStore(store);

  return { store, persistor };
};

export default initStore;
