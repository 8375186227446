import * as pageURLs from 'src/data/pageRoutes';
import { Modules } from 'src/types';
import { RouteConfig } from '../types';

export const ROUTE_CONFIG: RouteConfig[] = [
  {
    key: Modules.PendingTasks,
    label: 'Pending tasks',
    route: pageURLs.pendingTasksPageUrl,
    flagged: false,
  },
  {
    key: Modules.Activity,
    label: 'Activity',
    route: pageURLs.activityPageUrl,
    flagged: false,
  },
  {
    key: Modules.Budget,
    label: 'Budget',
    route: pageURLs.budgetPageUrl,
    flagged: false,
  },
  {
    key: Modules.Cards,
    label: 'Cards',
    route: pageURLs.cardsPageUrl,
    flagged: false,
  },
  {
    key: Modules.Payments,
    label: 'Payments',
    route: pageURLs.paymentsPageUrl,
    flagged: false,
  },
  {
    key: Modules.Transactions,
    label: 'Transactions',
    route: pageURLs.transactionsPageUrl,
    flagged: false,
  },
  {
    key: Modules.InstantPay,
    label: 'Instant pay',
    route: pageURLs.instantPayPageUrl,
    flagged: false,
  },
  {
    key: Modules.Invites,
    label: 'Invites',
    route: pageURLs.invitesPageUrl,
    flagged: false,
  },
];
