import { Bank } from '.';
import { Amount, Embeds, WithData } from '..';

export interface Transaction {
  id: string;
  amount: Amount;
  available_balance: Amount;
  embeds: Embeds;
  created_at: string;
  type: TransactionType;
  memo: string;
  causer_type: string;
  is_fee: boolean;
  fee: Amount;
  causer: {
    data: {
      contact: {
        data: {
          bank: WithData<Bank>;
          name: string;
          number: string;
        };
      };
      payer: {
        data: {
          bank: WithData<Bank>;
          name: string;
          number: string;
        };
      };
    };
  };
}

export enum TransactionType {
  DEBIT = 'debit',
  CREDIT = 'credit',
}
