import { Axios } from '../../helpers';
import {
  banksRoute,
  verifyBankAccountRoute,
  statesListRoute,
  countriesListRoute,
  rolesRoute,
  industriesRoute,
} from '../../data';
import {
  RDTFetchLists,
  RDTFetchStatesList,
  RDTVerifyBankAccount,
  RDTFetchRoutes,
} from './types';

export const getBanks = async (params?: RDTFetchLists) =>
  Axios.get(banksRoute, {
    params,
  });

export const getStatesList = (params?: RDTFetchStatesList) =>
  Axios.get(statesListRoute, { params });

export const getCountriesList = (params?: RDTFetchLists) =>
  Axios.get(countriesListRoute, { params });

export const getVerifyBankAccount = (params?: RDTVerifyBankAccount) =>
  Axios.get(verifyBankAccountRoute, { params });

export const getRolesAndPrivileges = (params?: RDTFetchRoutes) =>
  Axios.get(rolesRoute, { params });

export const getIndustries = (params?: RDTFetchRoutes) =>
  Axios.get(industriesRoute, { params });
