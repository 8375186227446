import { ledgersRoute, reportTransactionRoute } from 'src/data';
import { Axios } from 'src/helpers';
import {
  RDTGetTransactionDetailsParams,
  RDTGetTransactionsParams,
} from './types';

export const getTransactions = (params: RDTGetTransactionsParams) => {
  return Axios.get(`${ledgersRoute}`, {
    params,
  });
};

export const getTransaction = ({
  id,
  ...params
}: RDTGetTransactionDetailsParams) => {
  return Axios.get(`${ledgersRoute}/${id}`, {
    params,
  });
};

export const reportTransaction = (params: { id: string; reason: string }) => {
  return Axios.post(`${reportTransactionRoute}`, params);
};

export const getTransactionReceipt = (id: string) => {
  return Axios.post(`${ledgersRoute}/${id}/receipts`);
};
