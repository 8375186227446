import React, { ButtonHTMLAttributes } from 'react';
import { Button, ButtonVariant } from 'brass-ui-kit';
import cx from 'classnames';

import styles from './emptyScreen.module.scss';

export interface EmptyScreenProps {
  icon?: React.ReactElement;
  title: React.ReactNode;
  subText: React.ReactNode;
  action?: {
    text: React.ReactNode;
    props?: ButtonHTMLAttributes<any>;
  };
  className?: string;
}

const EmptyScreen: React.FC<EmptyScreenProps> = ({
  title,
  subText,
  action,
  icon,
  className,
}) => {
  return (
    <div className={cx(styles.emptyScreen, className)}>
      {icon && (
        <div aria-details='icon' className={styles.emptyScreen_icon}>
          {icon}
        </div>
      )}
      {typeof title === 'string' ? (
        <h3 className={styles.emptyScreen_title}>{title}</h3>
      ) : (
        title
      )}
      {typeof subText === 'string' ? (
        <p className={styles.emptyScreen_text}>{subText}</p>
      ) : (
        subText
      )}
      {action && (
        <Button
          className={styles.emptyScreen_button}
          variant={ButtonVariant.Primary}
          {...action.props}
        >
          {action.text}
        </Button>
      )}
    </div>
  );
};

export default EmptyScreen;
