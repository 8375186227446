import React from 'react';

export const useCloseTrigger = (
  trigger: React.RefObject<HTMLDivElement>,
  isOpen: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  React.useEffect(() => {
    const eventHandler = (evt: MouseEvent) => {
      if (trigger.current && !trigger.current.contains(evt.target as Node)) {
        setOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', eventHandler);
    }

    return () => {
      document.removeEventListener('click', eventHandler);
    };
  }, [isOpen]);
};
