import React from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'brass-ui-kit';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import Router from '../../modules/routing';
import { PageLoader } from '../../components/PageLoader';
import { initStore } from '../../redux';
import './reducerInitializer';
import 'brass-ui-kit/dist/styles/brass-money.css';
import 'react-phone-input-2/lib/style.css';
import 'src/styles/main.scss';
import AppInitializer from './AppInitializer';

export const { store, persistor } = initStore();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<PageLoader />} persistor={persistor}>
        <ErrorBoundary>
          <AppInitializer>
            <Router />
          </AppInitializer>
          <ToastContainer />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
};

export default Sentry.withProfiler(App);
