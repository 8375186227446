//App root URL
export const appRootUrl = `/`;

// Statement URLS
export const statementPageUrl = `/statements`;
export const requestStatementPageUrl = `/statements/verification`;
export const sendStatementPageUrl = `/statements/send`;
export const displayStatementPageUrl = `/statements/display`;

// Authentication URLS
export const authPageUrl = `/auth`;
export const applyPageUrl = `${authPageUrl}/apply`;
export const setPasswordPageUrl = `${authPageUrl}/set-password`;
export const loginPageUrl = `${authPageUrl}/login`;
export const verifyOtpPageUrl = `${authPageUrl}/verify`;
export const resetPasswordPageUrl = `${authPageUrl}/reset`;
export const changePasswordPageUrl = `${authPageUrl}/change-password`;
export const EmailVerificationPageUrl = `${authPageUrl}/email-verification`;

//Onboarding URLs
export const onboardingPageUrl = `/onboarding`;
export const kycPageUrl = `${onboardingPageUrl}/kyc`;
export const invitationPageUrl = `${onboardingPageUrl}/invitation`;

//Dashboard URLs
export const pendingTasksPageUrl = '/pending-tasks';
export const gettingStartedPageUrl = '/getting-started';
export const activityPageUrl = '/activity';
export const cashflowPageUrl = '/activity/cashflow';
export const budgetPageUrl = '/budget';
export const cardsPageUrl = '/cards';
export const paymentsPageUrl = '/payments';
export const transactionsPageUrl = '/transactions';
export const instantPayPageUrl = '/instant-pa';
export const invitesPageUrl = '/invites';
export const settingsPageUrl = '/settings';

export const allPendingTasksPageUrl = '/pending-tasks/all';
export const createBugdetPageUrl = '/budget/create';
export const newVirtualCardPageUrl = '/cards/new-virtual';
export const invitePartnerPageUrl = '/shared-account/invite';

//Send Money URL
export const sendMoneyPageUrl = '/send-money';
