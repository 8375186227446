import { handleError } from '../utils/errorHandlerUtils';

export default async (
  promise: Promise<any>,
  errorHandler?: (e: any) => void,
  pagination = false,
): Promise<any> => {
  const result: [any, any] = [null, null];
  try {
    const payload = await promise;
    if (pagination) {
      result[0] = payload.data;
    } else {
      result[0] = !!payload.data.data ? payload.data.data : payload.data;
    }
  } catch (e: any) {
    result[1] = e;
    if (errorHandler) {
      errorHandler(e);
    } else {
      handleError(e);
    }
  }
  return result;
};
