import React from 'react';
import { connect } from 'react-redux';
import { fetchActiveSession } from 'src/modules/auth';
import { getAccounts } from 'src/modules/account';
import { ATFetchActiveSession } from 'src/modules/auth/types';
import { fetchBanks, fetchRoles } from 'src/modules/common';
import { ATFetchCommons } from 'src/modules/common/types';
import { ReduxStore } from 'src/types';
import {
  CommonContext,
  DefaultValuesType,
  DEFAULT_VALUES,
} from '../common/context';

interface AppInitializerProps {
  fetchActiveSession: ATFetchActiveSession;
  fetchBanks: ATFetchCommons;
  fetchRoles: ATFetchCommons;
  hasSessionTimedOut: boolean;
  profile: ReduxStore['module/auth']['profile'];
  isLoggedIn: boolean;
  getAccounts: typeof getAccounts;
}

const AppInitializer: React.FC<AppInitializerProps> = ({
  fetchBanks,
  isLoggedIn,
  getAccounts,
  children,
}) => {
  const [state, setState] = React.useState(DEFAULT_VALUES);

  const handleStateUpdate = (newState?: Partial<DefaultValuesType>) =>
    setState(state => ({ ...state, ...newState }));

  React.useEffect(() => {
    if (isLoggedIn) {
      getAccounts();
      fetchBanks();
    }
  }, [isLoggedIn]);

  return (
    <CommonContext.Provider
      value={{ ...state, setCommonContextValue: handleStateUpdate }}
    >
      {children}
    </CommonContext.Provider>
  );
};

const mapStateToProps = (state: ReduxStore) => {
  const { hasSessionTimedOut, profile, isLoggedIn } = state['module/auth'];
  return {
    hasSessionTimedOut,
    profile,
    isLoggedIn,
  };
};

const mapActionToDispatch = {
  getAccounts,
  fetchActiveSession,
  fetchBanks,
  fetchRoles,
};

export default connect(mapStateToProps, mapActionToDispatch)(AppInitializer);
