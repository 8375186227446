import React from 'react';
import { LabelPosition, Switch } from 'brass-ui-kit';
import cx from 'classnames';
import { ChevronDown } from 'react-feather';

import AmountInput from 'src/components/AmountInput';
import { useCloseTrigger } from 'src/utils/hooks';
import { currencyFormatter } from 'src/utils/genericUtils';

import If from '../If';

import styles from './pageFilter.module.scss';
import { FormikErrors, FormikHelpers, FormikValues } from 'formik';

type AmountFilterProps = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  hasTypes: boolean;
};
const AmountFilter: React.FC<AmountFilterProps> = ({
  setFieldValue,
  hasTypes,
  values,
  errors,
}) => {
  const [isAmountOpen, setAmountOpen] = React.useState(false);

  const amountWrapper = React.useRef<HTMLDivElement>(null);

  useCloseTrigger(amountWrapper, isAmountOpen, setAmountOpen);

  const amountDisplay = (min_amount: string, max_amount: string) => {
    let [left, right] = '₦₦₦ - ₦₦₦'.split(' - ');

    if (!min_amount && !max_amount) return '';

    if (min_amount) {
      left = currencyFormatter.format(Number(min_amount));
    }

    if (max_amount) {
      right = currencyFormatter.format(Number(max_amount));
    }

    return [left, right].join(' - ');
  };

  return (
    <div
      className={cx(styles.pageFilter_left__column, {
        [styles.pageFilter_amount_error]: Boolean(errors['max_amount']),
      })}
      ref={amountWrapper}
    >
      <input
        id='amount'
        type='text'
        placeholder='Amount'
        readOnly
        autoComplete='off'
        value={amountDisplay(values.min_amount, values.max_amount)}
        className={styles.pageFilter_amount_trigger}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => {}}
        onFocus={() => {
          setAmountOpen(true);
        }}
      />

      <ChevronDown
        className={cx(styles.pageFilter_amount_chevron, {
          [styles.pageFilter_amount_chevron_open]: isAmountOpen,
        })}
      />

      <div
        className={cx(styles.pageFilter_amount, {
          [styles.pageFilter_amount__visible]: isAmountOpen,
        })}
      >
        <div
          className={cx(styles.pageFilter_amount_top, {
            [styles.pageFilter_amount_top_only]: !hasTypes,
          })}
        >
          <AmountInput
            className={styles.pageFilter_amount_input}
            label='Min. amount'
            value={values.min_amount}
            onValueChange={val => {
              setFieldValue('min_amount', val.value);
            }}
            variant='light'
          />
          <AmountInput
            className={styles.pageFilter_amount_input}
            label='Max. amount'
            value={values.max_amount}
            onValueChange={val => {
              setFieldValue('max_amount', val.value);
            }}
            variant='light'
          />
        </div>

        {errors['max_amount'] && (
          <p className={styles.pageFilter_amount_error_message}>
            {errors['max_amount']}
          </p>
        )}
        <If condition={hasTypes}>
          <div className={styles.pageFilter_amount_bottom}>
            <Switch
              name='amount-type'
              label='Debits'
              labelPosition={LabelPosition.Left}
              className={styles.pageFilter_amount_switch}
              onChange={(val: boolean) => {
                setFieldValue('debit', val);
              }}
            />
            <Switch
              name='amount-type'
              label='Credits'
              className={styles.pageFilter_amount_switch}
              labelPosition={LabelPosition.Left}
              onChange={(val: boolean) => {
                setFieldValue('credit', val);
              }}
            />
          </div>
        </If>
      </div>
    </div>
  );
};

export default AmountFilter;
