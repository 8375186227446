import React from 'react';
import cx from 'classnames';
import { Button, ButtonVariant } from 'brass-ui-kit';
import styles from './Modal.module.scss';
import { ModalProps } from './Modal';
import { useFormikContext } from 'formik';

interface LayoutProps {
  header?: ModalProps['header'];
  footer?: ModalProps['footer'];
  className?: string;
  size: 'medium' | 'small' | string;
  isFormik?: boolean;
  validateWithInitialValue?: boolean;
  handleClose?: VoidFunction;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  className,
  header,
  footer,
  size,
  isFormik,
  validateWithInitialValue,
  handleClose,
}) => {
  let isSubmitting, isValid, dirty;
  if (isFormik) {
    const formikProps = useFormikContext();
    isSubmitting = formikProps.isSubmitting;
    isValid = formikProps.isValid;
    dirty = formikProps.dirty;
  }

  let disableSubmitButton = isFormik && (!isValid || isSubmitting);

  if (validateWithInitialValue)
    disableSubmitButton = isFormik && (!(isValid && dirty) || isSubmitting);

  return (
    <div
      className={cx(styles.ModalLayout, className, {
        [styles[`ModalLayout_${size}`]]: !!size,
        [styles.ModalLayout_empty]: !children,
      })}
    >
      {header && (
        <header
          className={cx(
            styles.ModalLayout_header,
            styles[`ModalLayout_header_${header.alignment}`],
          )}
        >
          {header?.title && (
            <h1 className={styles.ModalLayout_header_title}>{header.title}</h1>
          )}
          {header?.description && (
            <p className={styles.ModalLayout_header_description}>
              {header.description}
            </p>
          )}
        </header>
      )}
      {children && (
        <section className={styles.ModalLayout_content}>{children}</section>
      )}
      {footer && (
        <footer
          className={cx(styles.ModalLayout_footer, {
            [styles.ModalLayout_footer_full]: footer?.primary?.size === 'full',
          })}
        >
          {footer?.primary?.size !== 'full' && (
            <Button
              block
              type='button'
              variant={ButtonVariant.Secondary}
              onClick={handleClose}
              {...footer.secondary?.props}
            >
              {footer?.secondary?.label || 'Cancel'}
            </Button>
          )}
          <Button
            block
            type={isFormik ? 'submit' : 'button'}
            disabled={disableSubmitButton}
            variant={ButtonVariant.Primary}
            loading={isSubmitting}
            className={cx({
              [styles.ModalLayout_footer_full_button]:
                footer?.primary?.size === 'full',
            })}
            {...footer.primary?.props}
          >
            {footer?.primary?.label}
          </Button>
        </footer>
      )}
    </div>
  );
};

Layout.defaultProps = {
  size: 'medium',
  isFormik: false,
  validateWithInitialValue: true,
};

export default Layout;
