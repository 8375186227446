import { ButtonVariant } from 'brass-ui-kit';
import { ButtonHTMLAttributes } from 'react';
import { AnyAction, Dispatch } from 'redux';

export interface IObject<T = any> {
  [x: string]: T;
}

export interface Amount {
  raw: string;
  formatted: string;
  formatted_display: string;
}

export type ApiRequestMiddlewarePromise = (
  helpers: IObject,
  getState: () => any,
  dispatch: Dispatch<AnyAction>,
) => Promise<any>;

export type ApiRequestMiddlewareTypes = [
  string | null,
  string | null,
  string | null,
];

export type Currency = 'NGN' | 'GHS' | 'USD' | 'ZAR';

export enum OS {
  DEFAULT = 'Default',
  ANDROID = 'Android',
  IOS = 'iOS',
  WINDOWS = 'Windows',
  MACOS = 'Mac OS',
  LINUX = 'Linux',
}

export type Embeds = Array<string>;

export type ButtonProps = {
  label?: string;
  size?: 'full' | 'default';
  props?: {
    disabled?: boolean;
    loading?: boolean;
    variant?: ButtonVariant;
  } & ButtonHTMLAttributes<any>;
};

export type FooterProps = {
  primary?: ButtonProps;
  secondary?: ButtonProps;
};

export type File = {
  key?: string;
  url: string;
  content_type?: string;
};

export interface IActionGenObject {
  types: ApiRequestMiddlewareTypes;
  promise: ApiRequestMiddlewarePromise;
}

export type Option<T = string> = { label: string; value: T };

export type OtpChannel = 'authenticator_app' | 'email';

export type Pagination = {
  current_page: number;
  per_page: number;
  total_pages: number;
  total?: number;
};

export type Payload<D, M = any> = {
  data: D;
  loading?: boolean;
  error?: string;
  pagination?: Pagination;
  meta?: M;
};

export type TFunc<V, R = void> = (x: V) => R;

export type WithData<T> = {
  data: T;
};
