import { API_URL } from 'src/config';
import AsyncWrapper from './asyncWrapper';
import Axios from './axios';

interface UploadConfigParams {
  isPublic: boolean;
  isAnon: boolean;
  customerId?: string;
}

export default class FileUploader {
  progressPerSec = 100 / 10000;

  clamp = (n: number, min: number, max: number) => {
    if (n < min) return min;
    if (n > max) return max;
    return n;
  };

  getSignedCredentials = async (
    filename: string,
    { isAnon, isPublic, customerId }: UploadConfigParams,
  ) => {
    const uploadUrlPath = isAnon ? 'upload_url_anon' : 'upload_url';
    const payload: any = { filename };
    const params: any = {};
    if (isAnon) {
      payload.customer = customerId;
      params.useApiKey = true;
    }
    if (isPublic) {
      payload.as_public = 1;
    }

    const [data] = await AsyncWrapper(
      Axios.post(`${API_URL}/${uploadUrlPath}`, payload, { params }),
    );
    return data;
  };

  upload = async (
    id: string,
    file: any,
    progress: number,
    updateProgress: (value: any) => void,
    config: UploadConfigParams,
  ) => {
    const {
      content_type = '',
      url,
      key,
    }: any = await this.getSignedCredentials(file?.name || '', config);

    const trickleProgress = () => {
      progress += this.progressPerSec;
      updateProgress(progress);
    };

    const progressIntervalHandler = window.setInterval(trickleProgress, 50);

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', content_type);

    const requestOptions: any = {
      method: 'PUT',
      headers: myHeaders,
      body: file,
      redirect: 'follow',
    };

    return fetch(url, requestOptions)
      .then(() => {
        updateProgress(100);
        clearInterval(progressIntervalHandler);
        return key;
      })
      .catch(error => console.log('error', error));
  };
}
