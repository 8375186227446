import { Card, Pagination } from 'brass-ui-kit';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useFilter } from 'src/utils/hooks';
import If from 'src/components/If';
import PageFilter from 'src/components/PageFilter';
import { Payload, TFunc, Transaction } from 'src/types';
import TransactionDetails from '../../flyouts/TransactionDetails';
import { getTransaction, getTransactions } from '../../functions';
import ReportTransactionModal from '../../modal/ReportTransactionModal';
import { RDTGetTransactionsParams, TransactionState } from '../../types';
import styles from './Transactions.module.scss';
import { PageLoader } from 'src/components/PageLoader';
import EmptyScreen from 'src/components/EmptyScreen';
import TransactionPane from '../../components/TransactionPane';
import { groupTransactionsByMonth } from 'src/utils/genericUtils';

const Transactions: React.FC<RouteComponentProps> = ({ history, location }) => {
  const searchParamsObject = new URLSearchParams(location.search);

  const [state, setState] = React.useState<TransactionState>({
    showTransactionFlyout: false,
    activeTransaction: null,
    reportedTransaction: null,
    activeTransactionId: null,
    showReportModal: false,
    transactions: [],
    pagination: { current_page: 0, per_page: 10, total_pages: 100 },
    loading: true,
  });

  const { updateFilters } = useFilter<RDTGetTransactionsParams>(
    { limit: 10, page: 1 },
    params => {
      fetchTransactions(params);
    },
    [null],
  );

  React.useEffect(() => {
    state.showTransactionFlyout &&
      state.activeTransaction &&
      searchParamsObject.set('id', state.activeTransaction.id);
    const search = searchParamsObject.toString();
    history.push({
      search,
    });
  }, [state.showTransactionFlyout, state.activeTransaction]);

  React.useEffect(() => {
    if (searchParamsObject.has('id')) {
      handleTransactionDetail(searchParamsObject.get('id') || '');
      handleStateUpdate({
        showTransactionFlyout: true,
      });
    }
  }, []);

  const handleTransactionDetail = (id: string) => {
    getTransaction(
      id,
      transaction => {
        handleStateUpdate({ activeTransaction: transaction });
      },
      () => {
        toggleTransactionFlyout();
      },
    );
  };

  const handleStateUpdate: TFunc<Partial<TransactionState>> = newState =>
    setState(state => ({ ...state, ...newState }));

  const toggleTransactionFlyout = (
    activeTransaction: Transaction | null = null,
  ) => {
    if (state.showTransactionFlyout) {
      searchParamsObject.delete('id');
      const search = searchParamsObject.toString();
      history.push({
        search,
      });
    }

    handleStateUpdate({
      showTransactionFlyout: !state.showTransactionFlyout,
      activeTransaction: !state.activeTransaction ? activeTransaction : null,
    });
  };

  const handleReportTransaction = (transaction: Transaction) => {
    handleStateUpdate({
      reportedTransaction: transaction,
    });
    toggleTransactionFlyout();
    toggleReportTransactionModal();
  };

  const toggleReportTransactionModal = () => {
    handleStateUpdate({
      showReportModal: !state.showReportModal,
    });
  };

  const fetchTransactions = (params: RDTGetTransactionsParams) => {
    getTransactions(params, handleFetchTransactionsSuccess);
    handleStateUpdate({ loading: true });
  };

  const handleFetchTransactionsSuccess = (ledger: Payload<Transaction[]>) => {
    handleStateUpdate({
      transactions: groupTransactionsByMonth(ledger.data),
      pagination: ledger.meta.pagination,
      loading: false,
    });
  };

  return (
    <main className={styles.Transactions}>
      <section className={styles.Transactions_heading}>
        <h1 className={styles.Transactions_heading_text}>All transactions</h1>
      </section>
      <PageFilter className={styles.Transactions_filter} />
      <Card className={styles.TransactionsList}>
        <If condition={state.loading}>
          <PageLoader className={styles.Transactions_loader} />
        </If>

        <If condition={!state.transactions.length && !state.loading}>
          <EmptyScreen
            title={'No Transaction'}
            subText={`You don't have any transaction yet`}
          ></EmptyScreen>
        </If>

        <If condition={!state.loading}>
          {state.transactions.map(({ month, data }, index) => (
            <>
              <div
                className={styles.TransactionsList_sub_header}
                key={`${month}-${index}`}
              >
                {month}
              </div>
              {data.map(transaction => {
                return (
                  <TransactionPane
                    key={transaction.id}
                    toggleTransactionFlyout={() =>
                      toggleTransactionFlyout(transaction)
                    }
                    data={transaction}
                  />
                );
              })}
            </>
          ))}
        </If>

        <Pagination
          className={styles.Transactions_pagination}
          limit={state.pagination.per_page}
          totalPage={state.pagination.total_pages}
          currentPage={state.pagination.current_page}
          paginate={(page, limit) => {
            updateFilters({ page, limit });
          }}
        ></Pagination>
      </Card>

      <TransactionDetails
        show={state.showTransactionFlyout}
        handleClose={toggleTransactionFlyout}
        transaction={state.activeTransaction}
        reportTransaction={handleReportTransaction}
      />

      <ReportTransactionModal
        show={state.showReportModal}
        transaction={state.reportedTransaction}
        handleClose={() => {
          toggleReportTransactionModal();
        }}
      />
    </main>
  );
};

export default Transactions;
