import { pick } from 'lodash';
import { IAction } from 'src/types';

export function setLoading<T>(state: T, action: IAction, key?: keyof T) {
  if (!key)
    return {
      ...state,
      loading: true,
    };
  return {
    ...state,
    [key]: {
      ...state[key],
      loading: true,
    },
  };
}

export function setResponse<T>(state: T, action: IAction, key?: keyof T) {
  const newState: any = {
    ...(key ? state[key] : state),
    data: action.payload?.data ? action.payload.data : action.payload,
    loading: false,
  };

  if (
    action.payload?.meta &&
    action.payload?.meta?.pagination &&
    ((key ? state[key] : state) as any)?.pagination
  ) {
    newState.pagination = pick(action.payload.meta.pagination, [
      'current_page',
      'per_page',
      'total_pages',
      'total',
    ]);
  }

  if (!key) return newState;

  return {
    ...state,
    [key]: newState,
  };
}

export function setError<T>(state: T, action: IAction, key?: keyof T) {
  const newState: any = {
    ...(key ? state[key] : state),
  };

  if (((key ? state[key] : state) as any)?.error)
    newState.error = action.payload;

  if (!key)
    return {
      ...state,
      ...newState,
      loading: false,
    };
  return {
    ...state,
    [key]: {
      ...newState,
      loading: false,
    },
  };
}
