import { Button, ButtonVariant, Card, Flyout } from 'brass-ui-kit';

import React from 'react';
import cx from 'classnames';

import {
  IncomingTransactionIcon,
  OutgoingTransactionIcon,
} from 'src/assets/svgs';
import If from 'src/components/If';

import styles from './TransactionDetails.module.scss';
import { format } from 'date-fns';
import { Transaction, TransactionType } from 'src/types';
import { PageLoader } from 'src/components/PageLoader';
import { getTransactionReceipt } from '../../functions';

interface TransactionDetailsFlyoutProps {
  show: boolean;
  handleClose: VoidFunction;
  reportTransaction: (transaction: Transaction) => void;
  transaction: Transaction | null;
}

const TransactionDetailsFlyout: React.FC<TransactionDetailsFlyoutProps> = ({
  show,
  handleClose,
  reportTransaction,
  transaction,
}) => {
  const [isDownloading, setDownloading] = React.useState(false);

  const handleDownload = async () => {
    setDownloading(true);
    await getTransactionReceipt(transaction!.id, () => {
      setDownloading(false);
    });
  };

  return (
    <Flyout
      className={styles.TransactionDetailsFlyout}
      isMaskOn={true}
      size={475}
      isOpen={show}
      closeFlyout={handleClose}
    >
      {!!transaction ? (
        <main className={styles.TransactionDetailsFlyout_container}>
          <div className={styles.TransactionDetailsFlyout_head}></div>
          <section className={styles.TransactionDetailsFlyout_card_container}>
            <Card className={styles.TransactionDetailsFlyout_card}>
              <If condition={transaction?.type == TransactionType.CREDIT}>
                <IncomingTransactionIcon />
              </If>
              <If condition={transaction?.type == TransactionType.DEBIT}>
                <OutgoingTransactionIcon />
              </If>
              <h5>
                {transaction?.type == TransactionType.DEBIT ? '-' : ''}
                {transaction?.amount.formatted_display}
              </h5>
              <span>{transaction?.causer_type}</span>
            </Card>
          </section>
          <section className={styles.TransactionDetailsFlyout_info}>
            <h6 className={styles.TransactionDetailsFlyout_info_header}>
              Transaction Information
            </h6>
            <div className={styles.TransactionDetailsFlyout_info_item}>
              <label>Amount</label>
              <span
                className={cx(styles.TransactionDetailsFlyout_info_value, {
                  [styles.TransactionDetailsFlyout_info_red]:
                    transaction?.type === TransactionType.DEBIT,
                  [styles.TransactionDetailsFlyout_info_green]:
                    transaction?.type === TransactionType.CREDIT,
                })}
              >
                {transaction?.amount.formatted_display}
              </span>
            </div>
            <If condition={transaction?.is_fee}>
              <div className={styles.TransactionDetailsFlyout_info_item}>
                <label>Fee</label>
                <span
                  className={
                    (styles.TransactionDetailsFlyout_info_value,
                    styles.TransactionDetailsFlyout_info_red)
                  }
                >
                  {transaction?.fee?.formatted_display}
                </span>
              </div>
            </If>

            <div className={styles.TransactionDetailsFlyout_info_item}>
              <label>Date</label>
              <span className={styles.TransactionDetailsFlyout_info_value}>
                {format(
                  new Date(
                    transaction?.created_at ? transaction?.created_at : '',
                  ),
                  'MMMM dd, yyyy, hh:mm:ss a',
                )}
              </span>
            </div>
            <If
              condition={
                !!transaction?.causer.data.contact &&
                !!transaction?.causer.data.payer
              }
            >
              <div className={styles.TransactionDetailsFlyout_info_item}>
                <label>Recipient</label>
                <span className={styles.TransactionDetailsFlyout_info_value}>
                  {!!transaction?.causer.data.contact
                    ? transaction?.causer.data.contact.data.name
                    : transaction?.causer.data.payer.data.name}
                </span>
              </div>
            </If>

            <If
              condition={
                !!transaction?.causer.data.contact &&
                !!transaction?.causer.data.payer
              }
            >
              <div className={styles.TransactionDetailsFlyout_info_item}>
                <label>Bank name</label>
                <span className={styles.TransactionDetailsFlyout_info_value}>
                  {!!transaction?.causer.data.contact
                    ? transaction?.causer.data.contact.data.bank.data.name
                    : transaction?.causer.data.payer.data.bank.data.name}
                </span>
              </div>
            </If>
            <If
              condition={
                !!transaction?.causer.data.contact &&
                !!transaction?.causer.data.payer
              }
            >
              <div className={styles.TransactionDetailsFlyout_info_item}>
                <label>Account number</label>
                <span className={styles.TransactionDetailsFlyout_info_value}>
                  {!!transaction?.causer.data.contact
                    ? transaction?.causer.data.contact.data.number
                    : transaction?.causer.data.payer.data.number}
                </span>
              </div>
            </If>
            <div className={styles.TransactionDetailsFlyout_info_item}>
              <label>Transaction ID</label>
              <span className={styles.TransactionDetailsFlyout_info_value}>
                {transaction?.id}
              </span>
            </div>
            <div className={styles.TransactionDetailsFlyout_info_item}>
              <label>Type</label>
              <span className={styles.TransactionDetailsFlyout_info_value}>
                {transaction?.type}
              </span>
            </div>
          </section>
          <section className={styles.TransactionDetailsFlyout_info}>
            <h6 className={styles.TransactionDetailsFlyout_info_header}>
              TRANSACTION memo
            </h6>
            <div className={styles.TransactionDetailsFlyout_info_item}>
              <label>{transaction?.memo}</label>
            </div>
          </section>
          <section className={styles.TransactionDetailsFlyout_actions}>
            <Button
              variant={ButtonVariant.PrimaryOutline}
              onClick={() => reportTransaction(transaction)}
            >
              Report Transaction
            </Button>
            <Button
              onClick={handleDownload}
              loading={isDownloading}
              variant={ButtonVariant.Primary}
            >
              Download Receipt
            </Button>
          </section>
        </main>
      ) : (
        <PageLoader />
      )}
    </Flyout>
  );
};

export default TransactionDetailsFlyout;
