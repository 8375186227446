import React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonVariant,
  DatePickerType,
  Select,
  SelectVariant,
} from 'brass-ui-kit';
import cx from 'classnames';
import qs from 'qs';
import { isEqual, omitBy, isEmpty } from 'lodash';
import { addDays, format } from 'date-fns';

import If from '../If';

import isDateDisabled from 'src/utils/dateRangeDisabledDate';
import DatePicker from 'src/components/DatePicker';
import { clean } from 'src/utils/genericUtils';

import { filterSchema } from './validation';
import AmountFilter from './AmountFilter';

import styles from './pageFilter.module.scss';
import { IObject, Option } from 'src/types';
import Spinner from '../Spinner';
import { DATE_FORMAT } from 'src/data';

type FormState = {
  from_date: string;
  to_date: string;
  credit: boolean;
  debit: boolean;
  max_amount: string;
  min_amount: string;
  type: string;
  statuses: string;
};

export type FilterIncludes = Array<
  'date-range' | 'amount' | 'amount.type' | 'type' | 'status'
>;

export type PageFilterProps = {
  className?: string;
  includes?: FilterIncludes;
  types?: Array<{ label: string; value: string }>;
  statuses?: Array<{ label: string; value: string }>;
  customValues?: IObject<string>;
  customFilters?: (props: FormikProps<any>) => React.ReactElement;
  loading?: boolean;
  data?: any[];
};

const PageFilter: React.FC<PageFilterProps> = ({
  className,
  includes,
  types,
  statuses,
  customValues,
  customFilters,
  loading,
  data,
}) => {
  const history = useHistory();
  const { type, ...rest } = qs.parse(location.search.substring(1));

  const intialFormValues = {
    from_date: '',
    to_date: '',
    credit: false,
    debit: false,
    max_amount: '',
    min_amount: '',
    type: '',
    statuses: '',
  };

  const handleSubmit = (values: FormState) => {
    const { credit, debit, ...rest } = values;
    const query: Omit<FormState, 'credit' | 'debit'> = { ...rest };
    const bothTrue = credit && debit;
    const bothFalse = !credit && !debit;

    if (!bothTrue && !bothFalse) {
      query.type = credit ? 'credit' : 'debit';
    }

    const queryString = qs.stringify(clean(query));
    return history.push({ search: `?${queryString}` });
  };

  const handleClearFilters = (formikProps: FormikProps<any>) => {
    history.push({
      search: '',
    });
    formikProps.resetForm();
  };

  const initalFormState: {
    debit?: boolean;
    credit?: boolean;
    type?: string;
  } = { ...rest };

  if (type) {
    if (type === 'debit' || type === 'credit') {
      initalFormState[`${type}`] = true;
    } else {
      initalFormState.type = type as string;
    }
  }

  return (
    <Formik<FormState>
      initialValues={{
        ...intialFormValues,
        ...initalFormState,
        ...(customValues ? customValues : {}),
      }}
      validationSchema={filterSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formikProps => {
        const { type, ...rest } = qs.parse(location.search.substring(1));
        const query: {
          debit?: boolean;
          credit?: boolean;
          type?: string;
        } = { ...rest };
        if (type) {
          if (type === 'debit' || type === 'credit') {
            query[`${type}`] = true;
          } else {
            query.type = type as string;
          }
        }

        const isApplyDisabled =
          isEqual(
            omitBy(formikProps.values, val => !Boolean(val)),
            query,
          ) || !isEmpty(formikProps.errors);

        return (
          <Form
            className={cx(styles.pageFilter, className)}
            onKeyDown={evt => {
              if (evt.key === 'Enter') {
                evt.preventDefault();
              }
            }}
          >
            <div
              className={styles.pageFilter_left}
              style={{
                gridTemplateColumns: `repeat(${includes!.length + 1}, 144px)`,
              }}
            >
              <If condition={includes!.includes('date-range')}>
                <DatePicker
                  type={DatePickerType.Date}
                  placeholder='Start date'
                  value={formikProps.values.from_date}
                  componentClassName={styles.pageFilter_component}
                  disabledDate={isDateDisabled(
                    'start',
                    formikProps.values.to_date,
                  )}
                  onChange={value =>
                    formikProps.setValues({
                      ...formikProps.values,
                      from_date: value,
                      to_date: format(addDays(new Date(value), 6), DATE_FORMAT),
                    })
                  }
                />

                <DatePicker
                  type={DatePickerType.Date}
                  placeholder='End date'
                  value={formikProps.values.to_date}
                  componentClassName={styles.pageFilter_component}
                  disabledDate={isDateDisabled(
                    'end',
                    formikProps.values.from_date,
                  )}
                  onChange={value => {
                    formikProps.setFieldValue('to_date', value);
                  }}
                />
              </If>

              <If condition={includes!.includes('amount')}>
                <AmountFilter
                  hasTypes={includes!.includes('amount.type')}
                  values={formikProps.values}
                  setFieldValue={formikProps.setFieldValue}
                  errors={formikProps.errors}
                />
              </If>

              <If condition={includes!.includes('type')}>
                <Select
                  className={styles.pageFilter_select}
                  placeholder='Type'
                  variant={SelectVariant.Light}
                  options={types || []}
                  value={formikProps.values.type}
                  onChange={(option: Option) => {
                    formikProps.setFieldValue('type', option.value);
                  }}
                />
              </If>

              <If condition={includes!.includes('status')}>
                <Select
                  className={styles.pageFilter_select}
                  placeholder='Status'
                  variant={SelectVariant.Light}
                  options={statuses || []}
                  value={formikProps.values.statuses}
                  onChange={(option: Option) => {
                    formikProps.setFieldValue('statuses', option.value);
                  }}
                />
              </If>
              {customFilters && customFilters(formikProps)}
            </div>
            <div className={styles.pageFilter_right}>
              {!isEmpty(data) && loading && (
                <div className={styles.pageFilter_right_loader}>
                  <Spinner />
                </div>
              )}
              <Button
                variant={ButtonVariant.Primary}
                type='submit'
                disabled={isApplyDisabled}
              >
                Apply filters
              </Button>
              <Button
                disabled={!location.search || !!rest?.id}
                variant={ButtonVariant.TextDark}
                onClick={() => handleClearFilters(formikProps)}
                type='button'
              >
                Clear filters
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

PageFilter.defaultProps = {
  includes: ['date-range', 'amount', 'amount.type'],
  types: [],
  statuses: [],
};

export default PageFilter;
