import React from 'react';
import { DEFAULT_COUNTRY_CODE } from '../../../config';

export type DefaultValuesType = {
  phoneCountryCode: string;
};

export type DefaultMethodsType = {
  setCommonContextValue: (x: Partial<DefaultValuesType>) => void;
};

export const DEFAULT_VALUES: DefaultValuesType = {
  phoneCountryCode: DEFAULT_COUNTRY_CODE.toLowerCase(),
};

export const DEFAULT_METHODS: DefaultMethodsType = {
  setCommonContextValue: () => null,
};

export const CommonContext = React.createContext({
  ...DEFAULT_VALUES,
  ...DEFAULT_METHODS,
});

CommonContext.displayName = 'Common';
