import React from 'react';
import { Modal as BrassUiKitModal, ModalSize } from 'brass-ui-kit';
import { Formik, Form, FormikProps, FormikConfig } from 'formik';
import cx from 'classnames';
import styles from './Modal.module.scss';
import { omit } from 'lodash';
import { FooterProps } from 'src/types';
import Layout from './Layout';

export interface ModalProps {
  header?: {
    image?: string;
    title?: React.ReactNode;
    description?: React.ReactNode;
    alignment?: 'left' | 'center' | 'right';
  };
  footer?: FooterProps;
  show: boolean;
  handleClose?: VoidFunction;
  className?: string;
  size?: ModalSize | number;
  isClosable?: boolean;
  formikConfig?:
    | ({ validateWithInitialValue?: boolean } & FormikConfig<any>)
    | null;
  formikComponent?:
    | ((React.ReactNode | React.ComponentType<FormikProps<any>>) &
        ((props: React.PropsWithChildren<FormikProps<any>>) => JSX.Element))
    | null;
}

const Modal: React.FC<ModalProps> = ({
  children,
  show,
  formikConfig,
  formikComponent: FormikComponent,
  size,
  ...props
}) => {
  const renderContent = (content: React.ReactNode) => {
    const modalSize = typeof size !== 'number' ? size! : '';
    if (formikConfig && FormikComponent) {
      return (
        <Formik
          {...omit(formikConfig, [
            'children',
            'component',
            'validateWithInitialValue',
          ])}
          enableReinitialize
          component={formikProps => (
            <Form>
              <Layout
                {...props}
                validateWithInitialValue={formikConfig.validateWithInitialValue}
                isFormik
                size={modalSize}
              >
                <FormikComponent {...formikProps} />
              </Layout>
            </Form>
          )}
        />
      );
    }
    return (
      <Layout {...props} size={modalSize}>
        {content}
      </Layout>
    );
  };
  return (
    <BrassUiKitModal
      isOpen={show}
      closeModal={props.handleClose}
      isClosable={props.isClosable}
      contentClassName={cx(styles.Modal, {
        [styles[`Modal_${size}`]]: size && typeof size !== 'number',
      })}
      size={typeof size === 'number' ? size : undefined}
    >
      {props?.header?.image ? (
        <img
          className={styles.ModalLayout_top_image}
          src={props.header.image}
        ></img>
      ) : null}
      {renderContent(children)}
    </BrassUiKitModal>
  );
};

Modal.defaultProps = {
  size: ModalSize.Medium,
  isClosable: true,
};

export default Modal;
