import React from 'react';
import { format, parse } from 'date-fns';
import { BRASS_LAUNCH_YEAR, CURRENT_YEAR, DATE_FORMAT } from 'src/data';
import {
  DatePicker as BrassUiKitDatePicker,
  DatePickerType,
  LabelPosition,
  YearsOrder,
} from 'brass-ui-kit';
import cx from 'classnames';
import { ChevronDown } from 'react-feather';

import { useCloseTrigger } from 'src/utils/hooks';

import styles from './datePicker.module.scss';

type DatePickerProps = {
  alignment?: 'left' | 'right';
  id?: string;
  variant?: 'light' | 'dark';
  type?: DatePickerType;
  placeholder?: string;
  value: string;
  disabledDate?: (date: Date) => boolean;
  dateFormat?: string;
  label?: string;
  labelPosition?: LabelPosition;
  className?: string;
  componentClassName?: string;
  onChange: (value: string) => void;
  startYear?: number;
  endYear?: number;
  isDisabled?: boolean;
  displayValue?: string;
  yearsOrder?: YearsOrder;
};

const DatePicker: React.FC<DatePickerProps> = ({
  alignment,
  id,
  variant,
  disabledDate,
  value,
  dateFormat,
  onChange,
  placeholder,
  startYear,
  yearsOrder,
  endYear,
  type,
  isDisabled,
  label,
  className,
  componentClassName,
  labelPosition,
  displayValue,
}) => {
  const [isDatePickerOpen, setDatePickerOpen] = React.useState(false);
  const wrapper = React.useRef<HTMLDivElement>(null);
  useCloseTrigger(wrapper, isDatePickerOpen, setDatePickerOpen);

  const datePickerProps = disabledDate ? { disabledDate } : {};

  const inputValue = displayValue
    ? displayValue
    : value && dateFormat
    ? format(parse(value, dateFormat, new Date()), 'MMM d, yyyy')
    : '';

  return (
    <div
      ref={wrapper}
      className={cx(styles.datePicker_wrapper, className, {
        [styles.datePicker_wrapper_leftLabel]: labelPosition === 'left',
      })}
    >
      {label && (
        <label className={styles.datePicker_label} htmlFor={id}>
          {label}
        </label>
      )}
      <div
        className={cx(
          styles.datePicker,
          styles[`datePicker__${variant}`],
          styles[`datePicker__${alignment}`],
        )}
      >
        <input
          id={id}
          type='text'
          placeholder={placeholder}
          readOnly
          autoComplete='off'
          className={styles.datePicker_trigger}
          disabled={Boolean(isDisabled)}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={() => {}}
          value={inputValue}
          onFocus={() => {
            setDatePickerOpen(true);
          }}
        />
        <ChevronDown
          className={cx(styles.datePicker_chevron, {
            [styles.datePicker_chevron_open]: isDatePickerOpen,
          })}
          onClick={() => {
            isDatePickerOpen
              ? setDatePickerOpen(false)
              : setDatePickerOpen(true);
          }}
        />
        <BrassUiKitDatePicker
          type={type!}
          onChange={(val: Date) => {
            dateFormat && onChange(format(val, dateFormat));
          }}
          value={value ? new Date(value) : new Date()}
          startYear={startYear}
          endYear={endYear}
          yearsOrder={yearsOrder}
          handleClose={() => setDatePickerOpen(false)}
          className={cx(styles.datePicker_component, componentClassName, {
            [styles.datePicker_component__visible]: isDatePickerOpen,
          })}
          {...datePickerProps}
        />
      </div>
    </div>
  );
};

DatePicker.defaultProps = {
  labelPosition: LabelPosition.Top,
  type: DatePickerType.Date,
  alignment: 'left',
  variant: 'dark',
  dateFormat: DATE_FORMAT,
  startYear: BRASS_LAUNCH_YEAR,
  endYear: CURRENT_YEAR,
  yearsOrder: YearsOrder.DSC,
  displayValue: '',
};

export default DatePicker;
