import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { loginPageUrl, appRootUrl } from 'src/data/pageRoutes';
import { ProfileData, ReduxStore } from 'src/types';
import { PrivateRouteComponent } from '../types';

interface PrivateRouteProps extends RouteProps {
  isLoggedIn: boolean;
  component?: PrivateRouteComponent;
  profile: ProfileData | null;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  isLoggedIn,
  component: Component,
  profile,
  location,
  render,
  ...rest
}) => {
  const isKycApproved = !!profile?.customer?.data?.kyc_verified_at;
  const searchParams = new URLSearchParams(location?.search);

  if (!isLoggedIn) {
    location?.pathname &&
      location?.pathname !== appRootUrl &&
      searchParams.set('redirect', location.pathname);
    const search = searchParams.toString();
    return (
      <Redirect
        to={{
          pathname: loginPageUrl,
          search,
        }}
      />
    );
  }

  if (isLoggedIn && isKycApproved)
    return (
      <Route
        {...rest}
        render={render ? render : undefined}
        component={Component ? Component : undefined}
      />
    );
  return (
    <Route
      {...rest}
      render={render ? render : undefined}
      component={Component ? Component : undefined}
    />
  );
};

const mapStateToProps = (state: ReduxStore) => {
  const authState = state['module/auth'];
  return {
    isLoggedIn: authState.isLoggedIn,
    profile: authState.profile,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
