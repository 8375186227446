import React from 'react';

type IfProps = {
  condition?: boolean;
  children: any;
};

const If: React.FC<IfProps> = ({ condition, children }) => {
  return condition ? children : null;
};

export default If;
