import { ReducerRegistry } from 'src/redux';
import * as AT from './actionTypes';
import { ReduxStore } from 'src/types';
import { setResponse, setLoading, setError } from 'src/utils/redux';
import { LOGOUT } from 'src/modules/auth';

type AccountsReducerState = ReduxStore['module/accounts'];

const initialState: AccountsReducerState = {
  accounts: {
    data: [],
    loading: true,
  },
};

ReducerRegistry.register<AccountsReducerState>(
  'module/accounts',
  (state = initialState, action) => {
    switch (action.type) {
      case AT.GET_ACCOUNTS:
        return setLoading<AccountsReducerState>(state, action, 'accounts');
      case AT.GET_ACCOUNTS_SUCCESS:
        return setResponse<AccountsReducerState>(state, action, 'accounts');
      case AT.GET_ACCOUNTS_FAILURE:
        return setError<AccountsReducerState>(state, action, 'accounts');
      case LOGOUT:
        return initialState;
      default:
        return state;
    }
  },
);
