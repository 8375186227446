export const TRANSACTION_REPORT_REASON = [
  {
    value: 'Debited but not received at counterparty end',
  },
  {
    value: 'Delayed transactions',
  },
  {
    value: 'Transaction processing but debit has occurred',
  },
  {
    value: 'Payment processing failed',
  },
  {
    value: 'Successful transaction with no debit on account',
  },
  {
    value: 'Others',
  },
];
