import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import apiRequestMiddleware from './middlewares/apiRequestMiddleware';
import Axios from 'src/helpers/axios';

/**
 * A registry for Redux middleware, allowing features to register their
 * middleware without needing to create additional inter-feature dependencies.
 */
class MiddlewareRegistry {
  _elements: any[];
  constructor() {
    const helpers = { client: Axios };

    // Inititalize default middlewares
    this._elements = [apiRequestMiddleware(helpers), thunk];
  }

  applyMiddleware(...additional: any[]) {
    const middlewares = [...this._elements, ...additional];

    return applyMiddleware(...middlewares);
  }

  register(middleware: any) {
    this._elements.push(middleware);
  }
}

/**
 * The public singleton instance of the MiddlewareRegistry class.
 */

export default new MiddlewareRegistry();
