import * as AT from './actionTypes';
import { AsyncWrapper } from '../../../helpers';
import { ATFetchCommons, ATSetLien } from '../types';
import * as api from '../api';
import { ReduxStore } from '../../../types';

export const fetchBanks: ATFetchCommons = () => ({
  types: [null, AT.FETCH_BANKS, null],
  promise: async () => {
    const [banks] = await AsyncWrapper(api.getBanks(), () => null);
    return banks;
  },
});

export const fetchStatesList: ATFetchCommons = () => ({
  types: [null, AT.FETCH_STATES_LIST, null],
  promise: async () => {
    const [states] = await AsyncWrapper(
      api.getStatesList({
        country: 'NG',
        limit: 1000,
        useApiKey: true,
        include: 'country',
      }),
      () => null,
    );
    return states;
  },
});

export const fetchCountriesList: ATFetchCommons = () => ({
  types: [null, AT.FETCH_COUNTRIES_LIST, null],
  promise: async () => {
    const [countries] = await AsyncWrapper(
      api.getCountriesList({
        useApiKey: true,
        limit: 1000,
      }),
      () => null,
    );
    return countries;
  },
});

export const fetchIndustries: ATFetchCommons = () => ({
  types: [null, AT.FETCH_INDUSTRIES, null],
  promise: async () => {
    const [industries] = await AsyncWrapper(
      api.getIndustries({
        useApiKey: true,
        limit: 1000,
      }),
      () => null,
    );
    return industries;
  },
});

export const fetchRoles: ATFetchCommons = () => ({
  types: [null, AT.FETCH_ROLES_AND_PRIVILEGES, null],
  promise: async () => {
    const [roles] = await AsyncWrapper(
      api.getRolesAndPrivileges({
        limit: 1000,
        include: 'permissions',
      }),
      () => null,
    );
    return roles;
  },
});

export const handleGlobalPromptModal = (
  payload: Partial<ReduxStore['module/common']['globalPromptModal']>,
) => {
  return {
    type: AT.HANDLE_GLOBAL_PROMPT_MODAL,
    payload,
  };
};

export const setLien: ATSetLien = payload => {
  return {
    type: AT.SET_LIEN,
    payload,
  };
};
