import React, { CSSProperties } from 'react';
import cx from 'classnames';
import Spinner from '../Spinner';

import styles from './PageLoader.module.scss';

interface PageLoaderProps {
  className?: string;
  message?: string;
  style?: CSSProperties;
}

const PageLoader: React.FC<PageLoaderProps> = ({
  className,
  message,
  style,
  ...rest
}) => {
  return (
    <section
      className={cx(styles.PageLoader, className)}
      style={style}
      {...rest}
    >
      <Spinner height={32} width={32} />
      {message && <p className={styles.PageLoader_msg}>{message}</p>}
    </section>
  );
};

export default PageLoader;
