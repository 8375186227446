import { AsyncWrapper } from 'src/helpers';
import {
  getFetchActiveSession,
  postLogin,
  postNewUser,
  postVerifyCode,
} from '../api';
import * as AT from './actionTypes';
import { clearToken } from 'src/utils/authUtils';
import { handleToken, sanitizeProfile } from '../utils';
import {
  ATFetchActiveSession,
  ATLogout,
  ATSetSessionTimeout,
  ATLoginUser,
  ATVerifyOtp,
  ATRegisterNewUser,
} from '../types';

import { persistor } from 'src/modules/app';
import { ProfileDataWithToken } from 'src/types';

export const registerNewUser: ATRegisterNewUser = (user, successCb) => ({
  types: [null, AT.REGISTER, null],
  promise: async () => {
    const [profile] = await AsyncWrapper(
      postNewUser(user, { auto_login: 1, useApiKey: true }),
    );
    if (profile) {
      const sanitizedProfile = await handleToken(profile, 'auth');
      successCb();
      return sanitizedProfile;
    }
  },
});

export const loginUser: ATLoginUser = (user, successCb) => ({
  types: [null, AT.LOGIN, null],
  promise: async () => {
    const [profile] = await AsyncWrapper(
      postLogin(user, {
        auto_login: 1,
        useApiKey: true,
      }),
    );
    if (profile) {
      const sanitizedProfile = await handleToken(profile, 'auth');
      successCb();
      return sanitizedProfile;
    }
  },
});

export const verifyOtp: ATVerifyOtp = (otp, successCb) => ({
  types: [null, AT.VERIFY_OTP, null],
  promise: async () => {
    const [profile] = await AsyncWrapper(
      postVerifyCode(otp, {
        useOtpToken: true,
      }),
    );
    if (profile) {
      const sanitizedProfile = await handleToken(profile, 'auth');
      successCb && successCb();
      return sanitizedProfile;
    }
  },
});

export const fetchActiveSession: ATFetchActiveSession = (
  successCb,
  include,
) => ({
  types: [null, AT.FETCH_ACTIVE_SESSION, null],
  promise: async () => {
    const [result] = await AsyncWrapper(getFetchActiveSession({ include }));
    if (result) {
      const profile: ProfileDataWithToken = result.data;
      if (successCb) {
        successCb();
      }
      return sanitizeProfile(profile);
    }
  },
});

export const logout: ATLogout = (purgeToken = true) => {
  purgeToken && clearToken('*');
  persistor.purge();
  return {
    type: AT.LOGOUT,
  };
};

export const setSessionTimeout: ATSetSessionTimeout = payload => {
  return {
    type: AT.SET_SESSION_TIMEOUT,
    payload,
  };
};
