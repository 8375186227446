import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const ENV: string = process.env.NODE_ENV!;

export const API_URL: string = process.env.REACT_APP_API_URL!;

export const API_KEY: string = process.env.REACT_APP_API_KEY!;

export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN!;

export const MONEY_WEBSITE_URL: string =
  process.env.REACT_APP_MONEY_WEBSITE_URL!;

export const DEFAULT_COUNTRY_CODE: string =
  process.env?.REACT_APP_DEFAULT_COUNTRY_CODE || 'NG';

export const SENTRY_KEY = process.env.REACT_APP_SENTRY_PUBLIC_KEY;

export const SENTRY_TRACES_SAMPLE_RATE = process.env
  ?.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
  ? Number(process.env?.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
  : 0;

export const SENTRY_ENV = process.env?.REACT_APP_SENTRY_ENV || ENV;

const MONEY_APP_PERSIST_KEY =
  process.env.REACT_APP_MONEY_APP_PERSIST_KEY! || 'DEMO_KEY';

export const PERSIST_CONFIG = {
  key: MONEY_APP_PERSIST_KEY,
  storage,
  version: 1,
  stateReconciler: autoMergeLevel2,
  throttle: 500,
  debug: process.env.NODE_ENV === 'development',
  whitelist: ['module/auth', 'module/common', 'module/accounts'],
};
