import { notifySuccess, Select, TextInput } from 'brass-ui-kit';
import { FormikProps } from 'formik';
import React from 'react';
import { Modal } from 'src/components/Modal';
import { Transaction } from 'src/types';

import { TRANSACTION_REPORT_REASON } from '../../data';
import { reportTransaction } from '../../functions';
import styles from './ReportTransactionModal.module.scss';

export interface ReportTransactionProps {
  show: boolean;
  handleClose: VoidFunction;
  transaction: Transaction | null;
}

export interface ReportTransactionState {
  show: boolean;
  handleClose: VoidFunction;
  transaction: Transaction;
}

const ReportTransactionModal: React.FC<ReportTransactionProps> = ({
  show,
  handleClose,
  transaction,
}) => {
  const initialValues = {
    reportType: '',
    info: '',
  };

  const handleSubmit = async (values: typeof initialValues) => {
    if (transaction?.id) {
      const params = {
        id: transaction.id,
        reason: `
        Reason: ${values.reportType}
        ${values.info ? `Additional Info: ${values.info}` : ''}
      `,
      };
      await reportTransaction(params, () => {
        notifySuccess('Transaction reported sucessfully.');
        handleClose();
      });
    }
  };

  return (
    <Modal
      className={styles.ReportTransactionModal}
      header={{
        title: 'Report transaction',
        description: 'Tell us why you wish to report this transaction',
        alignment: 'center',
      }}
      show={show}
      footer={{ primary: { label: 'Submit' } }}
      handleClose={handleClose}
      formikConfig={{
        initialValues: initialValues,
        onSubmit: handleSubmit,
      }}
      formikComponent={({
        handleSubmit,
        handleChange,
        values,
        errors,
        setFieldValue,
        touched,
      }: FormikProps<typeof initialValues>) => {
        return (
          <form
            className={styles.ReportTransactionModal_form}
            onSubmit={handleSubmit}
          >
            <Select
              className={styles.ReportTransactionModal_form_input}
              onChange={({ value }) => {
                setFieldValue('reportType', value);
              }}
              label='Reason for reporting'
              placeholder='Choose'
              options={TRANSACTION_REPORT_REASON}
              labelField='value'
              valueField='value'
              value={values.reportType}
              errorMessage={touched.reportType ? errors.reportType : undefined}
            />
            <TextInput
              label='Additional information'
              isMultiline
              onChange={handleChange}
              placeholder='Details about the transactions'
              errorMessage={touched.info ? errors.info : undefined}
              value={values.info}
              name='info'
            />
          </form>
        );
      }}
    ></Modal>
  );
};

export default ReportTransactionModal;
