import {
  signUpNewUserRoute,
  loginRoute,
  authorizeLoginRoute,
  activeSessionRoute,
  otpRoute,
  resetPasswordRoute,
  changePasswordRoute,
  verifyPasswordHashRoute,
  verifyEmailHashRoute,
} from '../../data/apiRoutes';
import { Axios } from '../../helpers';
import { IObject } from '../../types';
import { RDTAuthOtp, RDTUserApply, RDTUserLogin } from './types';

export const postNewUser = (user: RDTUserApply, params?: IObject) =>
  Axios.post(
    signUpNewUserRoute,
    {
      ...user,
    },
    { params },
  );

export const postLogin = (user: RDTUserLogin, params?: IObject) => {
  return Axios.post(loginRoute, user, {
    params,
  });
};

export const postResetPassword = (email: string) =>
  Axios.post(resetPasswordRoute, { email });

export const postVerifyResetPasswordHash = (hash: string) =>
  Axios.get(verifyPasswordHashRoute(hash));

export const postVerifyEmailHash = (hash: string) =>
  Axios.patch(verifyEmailHashRoute(hash), {
    params: { useApiKey: true },
  });

export const postChangePassword = (
  hash: string,
  password: string,
  password_confirmation: string,
) => Axios.post(changePasswordRoute(hash), { password, password_confirmation });

export const postVerifyCode = (otp: string, params?: IObject) =>
  Axios.post(
    authorizeLoginRoute,
    { otp },
    {
      params,
    },
  );

export const getFetchActiveSession = (params: IObject) =>
  Axios.get(activeSessionRoute, {
    params,
    headers: {
      'Cache-Control': 'no-cache',
    },
  });

export const postResendOtp = (data: RDTAuthOtp) =>
  Axios.post(otpRoute, {
    ...data,
  });
