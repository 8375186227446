//Auth routes
export const signUpNewUserRoute = '/auth/register';
export const loginRoute = '/auth/login';
export const resetPasswordRoute = '/auth/password-recovery';
export const authorizeLoginRoute =
  '/auth/login/authorise?include=accounts,industry';
export const activeSessionRoute = '/auth/me';
export const otpRoute = '/auth/otp';
export const verifyLinkedAccountOtpRoute = `${loginRoute}/authorise`;
export const verifyPasswordHashRoute = (hash: string) =>
  `auth/password-recovery/verify/${hash}`;
export const verifyEmailHashRoute = (hash: string) =>
  `auth/email-verifications/${hash}`;
export const changePasswordRoute = (hash: string) =>
  `auth/password-recovery/reset/${hash}`;
export const cashtagProfileResolveRoute = 'auth/cashtags';

//Statement routes
export const statementRoute = '/statements';

//onboarding routes
export const verifyBVNRoute = '/onboarding/identities/verify-bvn';
export const resolveBVNRoute = `/onboarding/identities/resolve-bvn`;
export const inviteMultipleRoute = `/invites/multiple/user`;
export const getInviteRoute = `/invites/user`;

//Pending tasks routes
export const pendingTasksRoute = '/dashboard/pending-tasks';
export const clearPendingTasksRoute = `${pendingTasksRoute}/clear-completed`;
export const addReferenceRoute = '/onboarding/references';
export const uploadBoardResolutionRoute = '/onboarding/documents';
export const legalSearchRoute = '/onboarding/legal-search-fee';

//Common routes
export const banksRoute = '/banking/banks';
export const verifyBankAccountRoute = `${banksRoute}/account-name`;
export const industriesRoute = '/industries';
export const getAccountRoute = '/accounts';

//Contacts routes
export const contactsRoute = '/contacts';
export const contactTagsRoute = `${contactsRoute}/tags`;
export const sentContactsRequestRoute = `${contactsRoute}/invites`;
export const receivedContactsRequestRoute = `${sentContactsRequestRoute}/requests`;

//Transactions routes
export const ledgersRoute = '/banking/ledgers';
export const reportTransactionRoute = '/finsec/reports';
export const statesListRoute = '/states';
export const countriesListRoute = '/countries';

//Invoices routes
export const outgoingInvoiceRoute = '/banking/payment-requests';
export const outgoingInvoiceSummaryRoute = `${outgoingInvoiceRoute}/summary`;
export const invoiceDefaultRoute = `${outgoingInvoiceRoute}/defaults`;
export const incomingInvoiceRoute = '/banking/incoming-requests';

//Payments routes
export const paymentTagsRoute = '/tags';
export const signatoriesRoute = '/banking/signatories';
export const paymentsRoute = '/banking/payments';
export const authorizationsRoute = '/banking/authorizations';
export const recurringMandatesRoute = '/banking/recurring-payment-mandates';

// Administration routes
export const administrationRoute = '/teams';
export const teamMembersRoute = `${administrationRoute}/people`;
export const teamInvitesRoute = `${administrationRoute}/invites`;
export const sendTeamInvitesRoute = `${administrationRoute}/invites`;
export const rolesRoute = '/roles';

//Settings routes
export const updateAddressRoute = '/onboarding/addresses';
export const updateCustomerRoute = '/auth/customer';
export const otpSetupRoute = `${otpRoute}/setup`;
export const approvalRulesRoute = `/banking/authorization-rules`;
export const complianceReferenceRoute = '/onboarding/references';
export const businessOwnersRoute = '/onboarding/people';
export const businessDocumentsRoute = '/onboarding/documents';

// Terminals routes
export const terminalsRoute = '/terminals';
export const terminalRequestsRoute = `${terminalsRoute}/requests`;
export const terminalsReportsRoute = `${terminalsRoute}/reports`;
export const terminalsReportsBreakdownRoute = `${terminalsReportsRoute}/breakdown`;
export const terminalsReportsSummaryRoute = `${terminalsReportsRoute}/summary`;
export const terminalTransactionsRoute = `${terminalsRoute}/transactions`;
export const terminalSettlementRoute = `${terminalsRoute}/settlements`;

//Accounts routes
export const accountsRoute = '/banking/accounts';
export const moveFundRoute = '/banking/transfers';

// Apps and Integrations route
export const slackAppRoute = 'https://slackapp.getbrass.co/api/slack/install';

// Capital routes
export const capitalRoute = '/capital';
export const capitalRepaymentsRoute = `${capitalRoute}/repayments`;
export const repaymentScheduleRoute = `${capitalRepaymentsRoute}/schedule-info`;
export const loanListRoute = `${capitalRoute}/loans`;
export const withdrawCapitalRoute = `${capitalRoute}/withdrawals`;
