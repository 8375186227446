import React, { InputHTMLAttributes } from 'react';

import ReactNumberFormat, {
  NumberFormatValues,
  NumberFormatProps,
} from 'react-number-format';
import { TextInput } from 'brass-ui-kit';
import cx from 'classnames';

import styles from './amountInput.module.scss';

interface AmountInputProps extends NumberFormatProps {
  id?: string;
  className?: string;
  label?: string;
  name?: string;
  value: string | number;
  onValueChange: (value: NumberFormatValues) => void;
  prefix?: string;
  placeholder?: string;
  allowNegative?: boolean;
  format?: string;
  variant?: 'light' | 'default';
  thousandSeparator?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  errorMessage?: string;
  onBlur?: InputHTMLAttributes<any>['onBlur'];
}

const AmountInput: React.FC<AmountInputProps> = ({
  className,
  label,
  onValueChange,
  prefix,
  allowNegative,
  thousandSeparator,
  variant,
  disabled,
  ...props
}) => {
  return (
    <ReactNumberFormat
      {...props}
      disabled={disabled}
      customInput={TextInput}
      thousandSeparator={thousandSeparator}
      label={label}
      className={cx(styles.amountInput, className, {
        [styles[`amountInput_${variant}`]]: variant,
      })}
      prefix={prefix}
      allowNegative={allowNegative}
      onValueChange={(values: NumberFormatValues) =>
        !disabled && onValueChange(values)
      }
    />
  );
};

AmountInput.defaultProps = {
  allowNegative: false,
  thousandSeparator: true,
  variant: 'default',
};

export default AmountInput;
