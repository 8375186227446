import { format } from 'date-fns';
import React from 'react';
import {
  IncomingTransactionIcon,
  KebabMenuIcon,
  OutgoingTransactionIcon,
} from 'src/assets/svgs';
import If from 'src/components/If';
import { Transaction, TransactionType } from 'src/types';
import styles from './TransactionPane.module.scss';

interface TransactionPaneProps {
  toggleTransactionFlyout: VoidFunction;
  data: Transaction;
}

const TransactionPane: React.FC<TransactionPaneProps> = ({
  toggleTransactionFlyout,
  data: { type, memo, created_at, amount, causer_type },
}) => {
  return (
    <div onClick={toggleTransactionFlyout} className={styles.TransactionPane}>
      <div className={styles.TransactionPane_content}>
        <If condition={type == TransactionType.CREDIT}>
          <IncomingTransactionIcon />
        </If>
        <If condition={type == TransactionType.DEBIT}>
          <OutgoingTransactionIcon />
        </If>
        <div className={styles.TransactionPane_content_title}>
          <label>{memo}</label>
          <p>{format(new Date(created_at), 'h:mm a')}</p>
        </div>
      </div>
      <div className={styles.TransactionPane_side}>
        <div className={styles.TransactionPane_side_title}>
          <label>{amount.formatted_display}</label>
          <If condition={causer_type !== undefined}>
            <p>{causer_type}</p>
          </If>
        </div>
        <KebabMenuIcon />
      </div>
    </div>
  );
};

export default TransactionPane;
