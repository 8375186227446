import { notifyError } from 'brass-ui-kit';
import { AsyncWrapper } from 'src/helpers';
import { Transaction } from 'src/types';
import { downloadFile } from 'src/utils/genericUtils';
import * as transactionsAPI from './api';
import { RDTGetTransactionsParams } from './types';
import { ATGetReceipt, ATReportTransactions } from './types/actions';

export const getTransactions = async (
  params: RDTGetTransactionsParams,
  successCb: (data: any) => void,
) => {
  const [response] = await AsyncWrapper(
    transactionsAPI.getTransactions(params),
    undefined,
    true,
  );
  response && successCb(response);
};

export const getTransaction = async (
  id: string,
  successCb: (res: Transaction) => void,
  failedCb: VoidFunction,
) => {
  const [response] = await AsyncWrapper(
    transactionsAPI.getTransaction({
      id,
      include: 'causer, causer.counterparty.counterparty.bank, account',
    }),
    () => {
      failedCb();
      notifyError('Failed to report this transaction, please try again.');
    },
  );
  response && successCb(response);
};

export const reportTransaction: ATReportTransactions = async (params, cb) => {
  const [response] = await AsyncWrapper(
    transactionsAPI.reportTransaction(params),
    () => {
      notifyError('Failed to report this transaction, please try again.');
    },
  );
  response && cb();
};

export const getTransactionReceipt: ATGetReceipt = async (id, cb) => {
  const [response] = await AsyncWrapper(
    transactionsAPI.getTransactionReceipt(id),
  );
  if (response) {
    await downloadFile(response);
  }
  response && cb && cb();
};
