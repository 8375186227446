import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { appRootUrl } from '../../../data';
import { ReduxStore } from '../../../types';

interface AuthRouteProps extends RouteProps {
  isLoggedIn: boolean;
  redirectTo?: string;
}

const AuthRoute: React.FC<AuthRouteProps> = ({
  isLoggedIn,
  location,
  redirectTo,
  ...props
}) => {
  if (isLoggedIn && !location?.pathname.endsWith(`email-verification`)) {
    const searchParams = new URLSearchParams(location?.search);
    const redirectFromParams = searchParams.get('redirect');
    const pathname = redirectFromParams || redirectTo;
    searchParams.delete('redirect');
    const search = searchParams.toString();
    return (
      <Redirect
        to={{
          pathname,
          search,
        }}
      />
    );
  }

  return <Route {...props} />;
};

AuthRoute.defaultProps = {
  redirectTo: appRootUrl,
};

const mapStateToProps = (state: ReduxStore) => {
  const authState = state['module/auth'];
  return {
    isLoggedIn: authState.isLoggedIn,
  };
};

export default connect(mapStateToProps)(AuthRoute);
